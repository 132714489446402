import {
    chakra,
    PopoverBody,
    PopoverContent,
    UnorderedList
} from '@chakra-ui/react';

export const popoverBody = chakra(PopoverBody, {
    label: 'dropdown-popover-body',
    baseStyle: {
        p:'0',
        w:'100%',
        position:'absolute'
    }
});

export const container = chakra(PopoverContent, {
    label: 'dropdown-list-container',
    baseStyle: {
        borderRadius: '0 0 8px 8px',
        transform: 'translate(0px, -8px) !important',
        w: 'calc(100% - 40px)',
        minW: '100% !important' ,
        maxHeight: '280px',
        overflow: 'auto',
        bgColor: '#FFF'
    }
});

export const list = chakra(UnorderedList, {
    label: 'dropdown-list-list',
    baseStyle: {
        listStyle: 'none',
        margin: 0
    }
});
