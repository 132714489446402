import { Grid, Box, VStack, Heading, Skeleton } from "@chakra-ui/react";
import { t } from "@lingui/macro";

export function CertificateReportLoader() {
    return (
        <VStack h={'calc(100vh)'} >
            <Grid w={'100%'} gap={4} background={'#F8FBFC'} p={'20px 40px'}>
                <Heading fontWeight={'400'}>{t`certificates`}</Heading>
                <Skeleton width={'100%'} height={'112px'}/>
                <Skeleton width={'100%'} height={'318px'}/>
            </Grid>
            <Box width={'100%'} height={'100%'}> 
                <Skeleton m={'40px'} height={'100%'}/>
            </Box>
        </VStack>
    )
}