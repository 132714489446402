import { useEffect, type PropsWithChildren } from 'react'
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { dynamicActivate } from "./i18n";
import { useQueryParams } from "../api/queries/certificate-reporting/use-fetcher";

export function LocalesProvider({ children }: PropsWithChildren){        
  const params = useQueryParams()
  const locale = params.get('locale') || ''

  useEffect(() => {
      dynamicActivate(locale);
    }, [locale]);    

  return (
      <I18nProvider i18n={i18n}>
        {children}
      </I18nProvider>
    )
}