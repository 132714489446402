import React, {
    ComponentType, PropsWithChildren, ReactNode, useMemo
} from 'react';

export type WrappedElement = ComponentType<PropsWithChildren<any>>;

export type WrappedElements = Array<WrappedElement>;

type Props = {
    wrappers: WrappedElements
    children: ReactNode
};

export function Compose ({
    wrappers = [], children
}: Props) {
    const components = useMemo(() => wrappers.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
    }, children), [wrappers, children]);

    return (<>{components}</>);
}

export function composeWrapper<TProps> (
    wrappers: WrappedElements
): ComponentType<TProps> {
    // eslint-disable-next-line react/display-name
    return ({ children }: PropsWithChildren<any>) => (
        <Compose wrappers={wrappers as any}>
            {children}
        </Compose>
    );
}
