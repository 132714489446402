import { Image, Flex, Text, Th, Skeleton } from "@chakra-ui/react";
import { TableHeaderProps } from "./grid-types";

export function TableHeader({name, image}: TableHeaderProps) {
    return (
        <Th
            borderBottom={'1px solid #DBE0E6'}
            borderRight={'1px solid #DBE0E6'}
            p={'10px 12px'}
            maxH={'72px'}
            maxW={'74px'}
        >
             <Flex
                alignItems={'center'}
                justifyContent={'center'}
                direction={'column'}
                gap={'10px'}
                textAlign={'center'}
                textTransform={'none'}
            >
                <Image maxH={'35px'} maxW={'50px'} src={image} fallback={<Skeleton h={'35px'} w={'55px'} />} />
                <Text>{name}</Text>
            </Flex>
        </Th>
       
    )
}