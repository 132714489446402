import { Tr, Td, Button, Flex, Checkbox, Text } from "@chakra-ui/react";
import { AddIcon, MinusIcon, ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { GridRowProps } from "./grid-types";
import { CERTIFICATE_ROW_VARIANT } from "../../utils/enums";
import { CellWrapper } from "./cell-wrapper";

export function GridRow({
        name,
        id,
        rowType,
        isRowExpanded,
        onRowExpand,
        isLastRecord,
        checkBoxProps,
        rankDetailProps
    } : GridRowProps) {
    const {
        checkBoxSize,
        checkBoxValue,
        isChecked,
        isIndeterminate,
        onCheckBoxChange
    } = checkBoxProps
    const {
        bronze,
        silver,
        gold,
        platinum,
        emerald,
        diamond,
        epic,
        legend,
        total
    } = rankDetailProps

    let borderTopLeftRadius = '0'
    let borderBottomLeftRadius = '0'
    let bgColor = '#FFF'
    let bgHoverColor = '#FFF'
    let fontWeight = 400
    let rightIcon = null
    let rowBgColor = '#FFF'
    switch (rowType) {
        case CERTIFICATE_ROW_VARIANT.class:
            borderTopLeftRadius = '20px'
            borderBottomLeftRadius = isRowExpanded ? '0' : '20px'
            bgColor = '#FFEDBD'
            bgHoverColor= '#FFE6A1'
            fontWeight =  500
            rightIcon= isRowExpanded ? <MinusIcon marginLeft={'16px'}/> : <AddIcon marginLeft={'16px'}/>
            rowBgColor = isChecked ? '#EEF8FE' : '#FFF'
            break;
        case CERTIFICATE_ROW_VARIANT.group:
            borderBottomLeftRadius = !isLastRecord || isRowExpanded ? '0' : '20px'
            bgColor = '#FFFAEE'
            bgHoverColor = '#FFEDBD'
            fontWeight = 500
            rightIcon = isRowExpanded ? 
                <ChevronUpIcon width={'30px'} height={'30px'} marginLeft={'16px'}/> :
                <ChevronDownIcon width={'30px'} height={'30px'} marginLeft={'16px'}/>
            break;
        case CERTIFICATE_ROW_VARIANT.student:
            break;
        default:
            break
    }
    return (
        <Tr bgColor={rowBgColor}>
            <Td maxWidth={'120px'}
                maxHeight={'72px'}
                p={'12px 20px'}
                bgColor={bgColor}
                _hover={{ bgColor: bgHoverColor }}
                borderBottom={'1px solid'}
                borderColor={'#DBE0E6'}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}>
                <Button w={'100%'} borderRadius={'none'} variant={'unstyled'} onClick={() => onRowExpand && onRowExpand(id)}>
                    <Flex justifyContent={'space-between'}>
                        <Text
                            maxH='42px'
                            lineHeight= '21px'
                            whiteSpace='normal'
                            overflow={'hidden'}
                            fontWeight={fontWeight}
                            fontSize={{sm:'13px', xxl:'16px'}}
                            textAlign={'left'}
                        >
                            { name }
                        </Text>
                        {rightIcon}
                    </Flex>      
                </Button>
            </Td>
            <Td
                borderRight= '1px solid #DBE0E6'
                bgColor={'#F8FBFC'}
                boxShadow='0px -1px 0px 0px #E2E8F0 inset'
            >
                <Checkbox
                    size={checkBoxSize}
                    value={checkBoxValue}
                    isIndeterminate={isIndeterminate}
                    isChecked={isChecked}
                    background={'white'}
                    border={'1px solid #CBD3DC'}
                    onChange={(e) =>
                        onCheckBoxChange(e)
                    }
                />
            </Td>
            <CellWrapper count={ bronze.count } />
            <CellWrapper count={ silver.count } />
            <CellWrapper count={ gold.count } />
            <CellWrapper count={ platinum.count } />
            <CellWrapper count={ emerald.count } />
            <CellWrapper count={ diamond.count } />
            <CellWrapper count={ epic.count } />
            <CellWrapper count={ legend.count } />
            <CellWrapper count={ total.count } />
        </Tr>
    )
}