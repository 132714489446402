import { createStandaloneToast } from "@chakra-ui/react";
import { t } from '@lingui/macro'
import { NotAllowedToastIcon } from "./not-allowed-icon";

const { toast } = createStandaloneToast();
export const restrictionToastId = 'RestrictionToast';
export const downloadToastId = 'DownloadToast';

export const displayErrorToast = () => {
    const toastId = 'genericErrorToast';
    const description = t`toast.generic.error`;
    if (!toast.isActive(toastId)) {
        toast({
            id: toastId,
            position: 'top',
            description: description,
            status: 'error',
            variant: 'genericError',
            duration: 5000,
            icon: <NotAllowedToastIcon />
        });
    }
}

export const displayRestrictionToast = () => {
    const description = t`toast.download.restriction`;
    if (!toast.isActive(restrictionToastId)) {
        toast({
            id: restrictionToastId,
            position: 'top',
            description: description,
            status: 'error',
            variant: 'genericError',
            duration: 5000,
            isClosable: true,
            icon: <NotAllowedToastIcon />
        });
    }
}

export const displayDownloadErrorToast = () => {
    const description = t`toast.download.error`;
    if (!toast.isActive(downloadToastId)) {
        toast({
            id: downloadToastId,
            position: 'top',
            description: description,
            status: 'error',
            variant: 'genericError',
            duration: 5000,
            isClosable: true,
            icon: <NotAllowedToastIcon />
        });
    }
}