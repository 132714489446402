import {
    IconProps
} from '@chakra-ui/react';
import React from 'react';

export enum DropdownVariant {
    halfStroke = 'dropdownHalfStroke',
    fullStroke = 'dropdownFullStroke',
    outline = 'outline'
}

export type RightIconProps = {
    iconProps?: IconProps
};

export type DropdownValue = string | number;

export type DropdownDisplay = string | null;

export type DropdownOption = {
    value: DropdownValue
    label: string
    disabled?: boolean
    rightIcon?: ({
        iconProps
    }: RightIconProps) => React.ReactElement
};

export type DropdownProps = {
    options: Array<DropdownOption>
    value: DropdownValue
    onOptionClick: (value: DropdownValue) => void
    label: string
    variant?: DropdownVariant
    labelConstant?: DropdownDisplay
    width?: string
    dividerIndex?: number|string
    isBatchDropdown?: boolean
};
