import { useQuery } from '@tanstack/react-query';
import { useAuthToken, useFetcher } from "../use-fetcher";
import { useEnvConfig } from '../../../../env-config';

export function usePastGetDownloadReport (enabled: boolean = false, requestIdentifier: string, pdfName: string) {

const { CERTIFICATE_GATEWAY } = useEnvConfig();
   const fetchDownloadPDF = useFetcher(
        CERTIFICATE_GATEWAY,
        `certificates/download/${requestIdentifier}`,
        "GET",
        {headers: {AuthToken : useAuthToken()}});

    const downLoadQuery =  useQuery(
        [Blob, "GetPastDownload", requestIdentifier], 
        fetchDownloadPDF, 
        { 
            refetchOnWindowFocus: false,
            enabled: enabled && requestIdentifier !== '',
            onSuccess: (data) => {
                if (data && enabled) {
                    const href = window.URL.createObjectURL(data as Blob);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', pdfName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }
    );

    return downLoadQuery
}
