import { Identifier, RankDetail } from "../../../api/queries/certificate-reporting/api-types";

export function getIdentifiers(certificates: any, certificateType: string) {
    let identifiers = [] as Identifier[]
    certificates.forEach(
        (certificate: { [x: string]: RankDetail; }) => {
            const rankType = certificate[`${certificateType}`] as RankDetail
            identifiers.push(...rankType.identifiers)
        }
    )
    return { count: identifiers.length, identifiers }
}