import type { ReducersMapObject } from 'redux';
import filtersContainerSlice from '../containers/filter-container/filter-container.slice';
import certificateResultsSlice from '../containers/certificate-results/certificate-results.slice';
import downloadContainerSlice from '../containers/download/download-container.slice';

export const rootReducer: ReducersMapObject = {
    [filtersContainerSlice.name]: filtersContainerSlice.reducer,
    [certificateResultsSlice.name]: certificateResultsSlice.reducer,
    [downloadContainerSlice.name] : downloadContainerSlice.reducer
};
