import { GridRowSkeleton } from "./grid-row-skeleton";

export function GridBodySkeleton() {
    return (
        <>
        { [...Array(5)].map( ( el, i) => ( 
            <GridRowSkeleton key={i}/>
            ) ) }
        </>
         
    )
}