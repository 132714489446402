import React, {
    createRef,
    RefObject
} from 'react';

import { DropdownTestIds } from '../dropdown-constants';
import {
    DropdownOption,
    DropdownValue
} from '../dropdown.types';
import DropdownListItem from './dropdown-list-item/dropdown-list-item';
import * as styled from './dropdown-list.styled';
import { useScrollBehavior } from '../../../hooks/use-scroll-behavior/use-scroll-behavior';
import { Divider } from '@chakra-ui/react';

type DropdownListProps = {
    options: Array<DropdownOption>
    onOptionClick: (optionValue: DropdownValue) => void
    value: DropdownValue
    dividerIndex?: number|string
    isBatchDropdown: boolean
};

export const DropdownList = ({
    options,
    onOptionClick,
    value,
    dividerIndex,
    isBatchDropdown
}: DropdownListProps) => {
    const { onScrollIntoView } = useScrollBehavior();

    return (
        <styled.popoverBody>
            <styled.container data-testid={DropdownTestIds.listContainer}>
                <styled.list>
                    {options.map((option, index) => {
                        const isActive = value === option.value;
                        const ref: RefObject<HTMLDataListElement> = createRef();
                        const handleFocus = () => {
                            if (ref.current) onScrollIntoView({ ref: ref.current });
                        };
                        const showDivider = dividerIndex === 'all' ? true : index === dividerIndex
                        return (
                            <>
                                { showDivider && 
                                    <Divider key={option.value} w={'95%'} opacity={1} transform={'translateX(3%)'}/>
                                }
                                <DropdownListItem
                                    handleFocus={handleFocus}
                                    isActive={isActive}
                                    key={option.value}
                                    option={option}
                                    ref={ref}
                                    onOptionClick={onOptionClick}
                                    isBatchDropdown={isBatchDropdown}
                                />
                            </>
                            
                        );
                    })}
                </styled.list>
            </styled.container>
        </styled.popoverBody>
    );
};
