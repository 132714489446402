import { Button, CircularProgress, Flex, Image, Skeleton, Text, createStandaloneToast, useDisclosure } from "@chakra-ui/react"
import { DownloadModal } from "../download/download-modal"
import { DownloadIcon } from '@chakra-ui/icons'
import { useAppSelector } from "../../store/store"
import { TABS } from "../../utils/enums"
import { usePastGetDownloadReport } from "../../api/queries/certificate-reporting/past-certificates/use-get-download"
import { useEffect, useState } from "react"
import { usePastDownload } from "../../hooks/use-past-download"
import { displayDownloadErrorToast } from "../toasts/toasts"
import { usePdfName } from "../download/use-pdf-name"
import { useEnvConfig } from "../../env-config"
import { PastBatchModal } from "../download/past-batch-modal"
import { Identifier } from "../../api/queries/certificate-reporting/api-types"
interface TileProps {
    name: string,
    image: string,
    count: number,
    identifiers?: Identifier[],
    type?: number
}

export function Tile({ name, image, count, identifiers, type }: TileProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [ isDownloading, setIsDownloading ] = useState(false)
    const [ isPastDownloading, setIsPastDownloading ] = useState(false)
    const [ isModalTriggered, setIsModalTriggered ] = useState(false)
    const [requestIdentifier, setRequestIdentifier] = useState('')
    const { toast } = createStandaloneToast();
    const { DOWNLOAD_LIMIT } = useEnvConfig()
    const { isOpen: isBatchOpen, onOpen: onBatchOpen, onClose: onBatchClose } = useDisclosure()
    const identifiersToDownload = identifiers?.flatMap(item => item.identifiers)

    const {
        activeTab,
        isSummaryLoading
    } = useAppSelector(
        rootState => rootState.certificateResultsSlice
    )
    const { mutation, mutate } = usePastDownload(type || 0);
    const pdfName = usePdfName(name, count, true)
    const downLoadQuery = usePastGetDownloadReport(requestIdentifier !== '' , requestIdentifier, pdfName)
    
    if (downLoadQuery.isSuccess) {
        setRequestIdentifier('')
    }
    if (downLoadQuery.isError) {
        toast.close('genericErrorToast')
        displayDownloadErrorToast()
        setRequestIdentifier('')
    }

    const onModalOpen = () => {
        setIsModalTriggered(true)
        onOpen()
    }

    useEffect( () => {
        if (setIsPastDownloading) {
            if (downLoadQuery.isFetching || mutation.status === 'loading') {
                setIsPastDownloading(true)
            } else {
                setIsPastDownloading(false)
            }
        }
    }, [downLoadQuery, setIsPastDownloading, mutation]); 
    
    useEffect(() => {
        function handleMutation() {
            if (mutation.status === 'success' && mutation.data) {
                const requestData = mutation.data as { data: string }
                if (requestData) {
                    setRequestIdentifier(requestData?.data)
                }
                mutation.reset();
            } else if (mutation.status === 'error') {
                displayDownloadErrorToast()
                setRequestIdentifier('')
                mutation.reset();
            }
        }
    handleMutation();
      // eslint-disable-next-line
    }, [mutation]);

    const onDownloadClick = () => {
        if (count > 0) {
            if (count > DOWNLOAD_LIMIT) {
                onBatchOpen()
            } else {
                setIsModalTriggered(true)
                mutate()
            }
        }
    }

    return (
        <Flex
            alignItems={'center'}
            direction={'column'}
            margin={{ sm: '10px 5px', xxxl: '20px 15px'}}
            border={'1px solid'}
            borderColor={'primary.400'}
            _hover={{ 
                border: '2px solid',
                borderColor: 'primary.500' }}
            height={{ sm: '130px', xxl: '170px' }}
            width={{ sm: '95px', xxl: '120px' }}
            borderRadius={'20px'}
            p={'15px 5px'}
        >
            <Image h={{ sm: '40px', xxl: '64px' }} src={image} fallback={<Skeleton h={'64px'} w={'58px'} />}/>
            <Text fontSize={'13px'}>{name}</Text>
            { isSummaryLoading ? 
                <Skeleton w={'78px'} h={'33px'} m={'10px'} />
                :
                <Button 
                variant={'primary'}
                isDisabled={count === 0}
                bg={count > 0 ? 'linear-gradient(149deg, #00ABE8 4.33%, #007ACC 45.04%, #0063A7 100%)' : '#F4F8FA'}
                color={count > 0 ? '#fff' : '#33475F'}
                fontWeight={'500'}
                fontSize={{ sm: '12px', xxl: '15px' }}
                borderRadius={'50px'}
                height={{ sm: '25px', xxl: '33px' }}
                width={{ sm: '60px', xxl: '78px' }}
                p={{ sm: '3px', xxl: '5px' }}
                m={{ sm: '5px', xxl: '10px' }}
                textAlign={'center'}
                alignItems={'center'}
                _disabled={{cursor: 'not-allowed', _hover: { bg: '#F4F8FA'}}}
                onClick={ activeTab === TABS.newCertificates ? onModalOpen : onDownloadClick }
                opacity={(isPastDownloading || isDownloading) && isModalTriggered ? '70%' : 'inherit'}
            >
                { (isPastDownloading || isDownloading) && isModalTriggered ? 
                    <CircularProgress isIndeterminate size={'15px'} />
                    : 
                    <>
                        { count > 0 ? count : '-' }
                        { count > 0 && <DownloadIcon marginLeft={'4px'} /> }
                    </>
                }
            </Button>}
            
            <DownloadModal
                variant="modal"
                name={name}
                count={count}
                isOpen={count > 0 && isOpen}
                onClose={onClose}
                identifiers={identifiers || []}
                setIsDownloading={setIsDownloading}
            />
            
            <PastBatchModal
                count={count}
                name={name}
                isOpen={isBatchOpen}
                identifiers={identifiersToDownload || []}
                onClose={onBatchClose}
                type={type}
            />
        </Flex>
    )
}