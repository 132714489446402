import { useQueries } from '@tanstack/react-query';
import { useAuthToken, useFetcher } from "./use-fetcher";
import { useEnvConfig } from '../../../env-config';

export function useInitialData () {
    const { CERTIFICATE_REPORT_GATEWAY } = useEnvConfig();

    const fetchClassrooms = useFetcher(
        CERTIFICATE_REPORT_GATEWAY,
        "report/classrooms",
        "GET",
        {headers: {AuthToken : useAuthToken()}}
    );
    const fetchDateTimeFilters = useFetcher(
        CERTIFICATE_REPORT_GATEWAY,
        "report/dateTimeFilters",
        "GET",
        {headers: {AuthToken : useAuthToken()}}
    );

    const results = useQueries({
        queries: [
            { queryKey: ['GetClassrooms'], queryFn: fetchClassrooms, staleTime: Infinity },
            { queryKey: ['GetDateTimeFilters'], queryFn: fetchDateTimeFilters, staleTime: Infinity }
        ]
    });

    return results
}
