import styled from "@emotion/styled";

export const DotFlashingWrapper = styled.div`
    .dot-flashing {
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #4DC4EF;
        color: #4DC4EF;
        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;
    }

    .dot-flashing::before, .dot-flashing::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
    }

    .dot-flashing::before {
        left: -25px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #4DC4EF;
        color: #4DC4EF;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
    }

    .dot-flashing::after {
        left: 25px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #4DC4EF;
        color: #4DC4EF;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
    }

    @keyframes dot-flashing {
        0% {
            background-color: #4DC4EF;
        }
        50%, 100% {
            background-color: #D0F1FD;
        }
    }
`;
