import { HStack } from "@chakra-ui/react";
import { Tile } from "./rank-tile";
import Bronze from "../../assets/bronze.svg"
import Silver from "../../assets/silver.svg"
import Gold from "../../assets/gold.svg"
import Platinum from "../../assets/platinum.svg"
import Emerald from "../../assets/emerald.svg"
import Diamond from "../../assets/diamond.svg"
import Epic from "../../assets/epic.svg"
import Legend from "../../assets/legend.svg"
import { useAppSelector } from "../../store/store";
import { t } from "@lingui/macro";

export function NewCertificateTiles() {

    const {
        newCertificates,
    } = useAppSelector(
        rootState => rootState.certificateResultsSlice
    )
    const {
        bronze,
        silver,
        gold,
        platinum,
        emerald,
        diamond,
        epic,
        legend } = newCertificates
    return (
        <HStack>
            <Tile name={t`certificate.level.bronze`} image={Bronze} count={bronze.count} identifiers={bronze.identifiers} />
            <Tile name={t`certificate.level.silver`} image={Silver} count={silver.count} identifiers={silver.identifiers} />
            <Tile name={t`certificate.level.gold`} image={Gold} count={gold.count} identifiers={gold.identifiers} />
            <Tile name={t`certificate.level.platinum`} image={Platinum} count={platinum.count} identifiers={platinum.identifiers} />
            <Tile name={t`certificate.level.emerald`} image={Emerald} count={emerald.count} identifiers={emerald.identifiers} />
            <Tile name={t`certificate.level.diamond`} image={Diamond} count={diamond.count} identifiers={diamond.identifiers} />
            <Tile name={t`certificate.level.epic`} image={Epic} count={epic.count} identifiers={epic.identifiers} />
            <Tile name={t`certificate.level.legend`} image={Legend} count={legend.count} identifiers={legend.identifiers} />
        </HStack>
    )
}