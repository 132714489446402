import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit';
import { Classroom, Group, TimeFilterDetail } from '../../api/queries/certificate-reporting/api-types';
import { DATE_FILTER_TYPE } from '../../utils/enums';
import {
    getAllClasses,
    getAllGroups,
    getDateTimeFilters,
    getPastYearFilters,
    getStudentIDsAllClasses,
    getStudentIDsAtClassLevel,
    getStudentIDsAtGroupLevel
} from './__helpers__/filter-helper';

interface FiltersState {
    classroom: {
        data: Classroom[],
        selected: Classroom | null,
        studentIDs: string[]
    },
    group: {
        data: Group[],
        selected: Group | null,
        studentIDs: string[]
    },
    timeFilter: {
        data: TimeFilterDetail[],
        selected: TimeFilterDetail | null,
        academicYear: TimeFilterDetail | null,
    }
    studentIDs: string[],
    isTimeModalActive: boolean
}

const initialState: FiltersState = {
    classroom: {
        data: [],
        selected: null,
        studentIDs: []
    },
    group: {
        data: [],
        selected: null,
        studentIDs: []
    },
    timeFilter: {
        data: [],
        selected: null,
        academicYear: null
    },
    studentIDs: [],
    isTimeModalActive: false
}

interface FiltersActionType {
    classRooms: Classroom[]
}

interface DateFiltersActionType {
    dateTimeFilters: TimeFilterDetail[],
    isPastYears?: boolean
}
interface CustomDateActionType {
    startDate: string,
    endDate: string
}


const filtersContainerSlice = createSlice({
    name: 'filtersContainerSlice',
    initialState,
    reducers: {
        setClassGroupFiltersInitialData (state: FiltersState, action: PayloadAction<FiltersActionType>) {
            const classRoomData: Classroom[] = action.payload.classRooms
            const newClassRoomData = [...classRoomData]
            if (classRoomData.length > 1) {
                newClassRoomData.unshift(getAllClasses())
            }
            state.classroom = {
                data: newClassRoomData,
                selected: newClassRoomData[0],
                studentIDs: getStudentIDsAllClasses(classRoomData)
            }
            state.group = {
                data: [getAllGroups()],
                selected: getAllGroups(),
                studentIDs: getStudentIDsAllClasses(classRoomData)
            }
            state.studentIDs = getStudentIDsAllClasses(classRoomData)
            
        },
        setSelectedClassroom (state: FiltersState, action: PayloadAction<number>) {
            const classRoomId = action.payload
            const updatedClassroom = state.classroom.data.filter(classRoom => classRoom.id === action.payload)[0]
            state.classroom.selected = updatedClassroom;
            
            const newGroupData = [...updatedClassroom.groups]
            if (newGroupData.length > 1) {
                newGroupData.unshift(getAllGroups())
            }
            state.group.data = newGroupData;
            state.group.selected = newGroupData[0];
            
            if (classRoomId === 0) {
                state.studentIDs = getStudentIDsAllClasses(state.classroom.data)
                state.group.studentIDs = getStudentIDsAllClasses(state.classroom.data)
            } else {
                state.studentIDs = getStudentIDsAtClassLevel(updatedClassroom)
                state.group.studentIDs = getStudentIDsAtClassLevel(updatedClassroom)
            }

        },
        setSelectedGroup (state: FiltersState, action: PayloadAction<number>) {
            const groupId = action.payload
            const updatedGroup = state.group.data.filter(group => group.id === action.payload)[0]
            state.group.selected = updatedGroup;
            if (groupId === 0 && state.classroom.selected) {
                state.studentIDs = getStudentIDsAtClassLevel(state.classroom.selected)
                state.group.studentIDs = getStudentIDsAtClassLevel(state.classroom.selected)
            } else {
                state.studentIDs = getStudentIDsAtGroupLevel(updatedGroup)
                state.group.studentIDs = getStudentIDsAtGroupLevel(updatedGroup)
            }
        },
        setDateTimeFiltersInitData(state: FiltersState, action: PayloadAction<DateFiltersActionType>) {
            const dateTimeFilters: TimeFilterDetail[] = getDateTimeFilters(action.payload.dateTimeFilters)
            state.timeFilter = {
                data: dateTimeFilters,
                selected: dateTimeFilters.find(filter => filter.filterType === DATE_FILTER_TYPE.LastWeek) || null,
                academicYear: dateTimeFilters.find(filter => filter.filterType === DATE_FILTER_TYPE.CurrentAcademicYear) || null
            }
        },
        setDateTimeFilter(state: FiltersState, action: PayloadAction<string>) {
            state.timeFilter.selected = state.timeFilter.data.filter(filter => filter.filterType === action.payload)[0]
        },
        updateDateTimeFilter(state: FiltersState, action: PayloadAction<CustomDateActionType>) {
            const selectedTimeFilter = state.timeFilter.selected as TimeFilterDetail
            state.timeFilter.selected = {
                ...selectedTimeFilter,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate
            }
        },
        setTimeModal(state: FiltersState, action: PayloadAction<boolean>) {
            state.isTimeModalActive = action.payload
        },
        setPastYearFilters(state: FiltersState, action: PayloadAction<DateFiltersActionType>) {
            const pastDateFilters: TimeFilterDetail[] = getPastYearFilters(action.payload.dateTimeFilters)
            if (action.payload.isPastYears) {
                state.timeFilter.data = state.timeFilter.data.concat(pastDateFilters)
            } else {
                const existingFiltersData = state.timeFilter.data;
                state.timeFilter = {
                    data: existingFiltersData.filter(
                        timeFilter => !timeFilter.filterType.includes('PastYears')),
                    selected: existingFiltersData.find(filter => filter.filterType === DATE_FILTER_TYPE.LastWeek) || null,
                    academicYear: existingFiltersData.find(filter => filter.filterType === DATE_FILTER_TYPE.CurrentAcademicYear) || null
                }
            }
        }
    }
});

export const { 
    setClassGroupFiltersInitialData,
    setSelectedClassroom,
    setSelectedGroup,
    setDateTimeFiltersInitData,
    setDateTimeFilter,
    updateDateTimeFilter,
    setTimeModal,
    setPastYearFilters
} = filtersContainerSlice.actions;

export default filtersContainerSlice;