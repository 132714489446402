import { useMutation } from '@tanstack/react-query';
import { useAuthToken, useFetcher } from "../use-fetcher";
import { useEnvConfig } from '../../../../env-config';

export function useSubmitDownloadRequest (identifiers: string[], fullColor: boolean) {
    const { CERTIFICATE_REPORT_GATEWAY } = useEnvConfig();

    const postSubmitDownloadRequest = useFetcher(
        CERTIFICATE_REPORT_GATEWAY,
        "report/submitRequest",
        "POST",
        {headers: {AuthToken : useAuthToken()}},
        {
            identifiers,
            fullColor,
        });

    return useMutation({
        mutationFn: postSubmitDownloadRequest,
        mutationKey: ['SubmitRequest']
    })
}
