import { i18n } from "@lingui/core";
import moment from 'moment';
import 'moment/locale/es-mx';

export const locales = {
  en_AU: "en_AU",
  es_MX: "es_MX",
  en_US: "en_US", 
  en_CA: "en_CA"
};
export const defaultLocale = "en_AU";

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
  locale = locale.replace('-', '_')  
  const selectedLocale = Object.values(locales).includes(locale) ? locale : defaultLocale
  moment.locale(selectedLocale);
  
  const { messages } = await import(`./${selectedLocale}/messages`);  
  i18n.load(selectedLocale, messages);
  i18n.activate(selectedLocale);
}