import { useBoolean } from "@chakra-ui/react"

import { GridRow } from "./grid-row"
import { CheckBoxProps, ClassRowProps, RankDetailProps } from "./grid-types"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/store"
import { GroupRow } from "./group-row"
import { addIdentifiersToDownload, removeIdentifiersToDownload, setCheckedAllItems } from "../../containers/download/download-container.slice"
import { getCertificatesCountForClass } from "./__helpers__/result-grid-helper"
import { CERTIFICATE_ROW_VARIANT } from "../../utils/enums"
import { Identifier } from "../../api/queries/certificate-reporting/api-types"

export function ClassRow({ classroom, classIndex }: ClassRowProps) {
    const [isClassExpanded, setIsClassExpanded] = useBoolean(false)
    const [classId, setClassId] = useState<number | string>(0)
    const dispatch = useAppDispatch()
    const {
        group
    } = useAppSelector(
        rootState => rootState.filtersContainerSlice
    )

    const filteredGroups = group.selected.id === 0 ? classroom.groups : 
        classroom.groups.filter(classRoomGroup => classRoomGroup.id === group.selected.id)
    const [checkedGroups, setCheckedGroups] = useState(filteredGroups.map(() => false))
    
    const [allGroupsChecked, setAllGroupsChecked] = useState(false)
    const [isIndeterminate, setIsIndeterminate] = useState(false)
    
    const {
        studentCertificates
    } = useAppSelector(
        rootState => rootState.certificateResultsSlice
    )

    const {
        checkedAllItems
    } = useAppSelector(
        rootState => rootState.downloadContainerSlice
    )

    useEffect(() => {
        setAllGroupsChecked(checkedGroups.every(Boolean));
        setIsIndeterminate(checkedGroups.some(Boolean) && !allGroupsChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allGroupsChecked, checkedGroups])
    
    useEffect(() => {
        setCheckedGroups(filteredGroups.map(() => checkedAllItems[classIndex]))
    },[checkedAllItems, classIndex, filteredGroups, dispatch])

    
    function onRowExpand(id: number | string) {
        setIsClassExpanded.toggle()
        setClassId(id)
    }

    const rankDetailProps = () => ({
        ...getCertificatesCountForClass(classroom, studentCertificates) as RankDetailProps
    })

    function onCheckBoxChange(e: any) {
        dispatch(setCheckedAllItems([
            ...checkedAllItems.slice(0, classIndex),
            e.target.checked,
            ...checkedAllItems.slice(classIndex + 1)
        ]))
        
        setCheckedGroups(filteredGroups.map(() => e.target.checked))

        const identifiers  = rankDetailProps().total.identifiers as Identifier[]
        const totalIdentifiers = identifiers.flatMap(item => item.identifiers)
        if (e?.target?.checked) {
            dispatch(addIdentifiersToDownload({ totalIdentifiers }))
            dispatch(addIdentifiersToDownload({ totalIdentifiers }))
        } else {
            dispatch(removeIdentifiersToDownload({ totalIdentifiers }))
        }
    }
    const checkBoxProps = () => ({
        checkBoxSize: 'lg',
        checkBoxValue: `${classroom.id}`,
        isChecked: allGroupsChecked,
        isIndeterminate,
        onCheckBoxChange
    } as CheckBoxProps)

    

    const gridRowProps = () => ({
        name: classroom.name,
        id: classroom.id,
        rowType: CERTIFICATE_ROW_VARIANT.class,
        isRowExpanded: isClassExpanded,
        isLastRecord: checkedAllItems.length -1 === classIndex,
        onRowExpand,
        checkBoxProps: checkBoxProps(),
        rankDetailProps: rankDetailProps()
    })
    
    return (
        <>
            <GridRow {...gridRowProps()}/>
            { isClassExpanded && classroom.id === classId && filteredGroups.map(
                    (group, index) => {
                        return <GroupRow
                                    key={group.id}
                                    classId={classroom.id}
                                    group={group}
                                    groupIndex={index}
                                    classIndex={classIndex}
                                    checkedItems={checkedGroups}
                                    setCheckedItems={setCheckedGroups}
                                />
                }) }
        </>
    )
}