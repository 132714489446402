/* eslint-disable max-len */
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/store';
import { DateMoment } from '../datepicker/datepicker.types';
import { updateDateTimeFilter } from '../../containers/filter-container/filter-container.slice';
import { useCertificateSummaryReport } from '../../hooks/use-summary-report';
import { StudentCertificates, TimeFilterDetail } from '../../api/queries/certificate-reporting/api-types';
import { disableSkeleton, enableSkeleton, setNewCertificates } from '../../containers/certificate-results/certificate-results.slice';
import { Datepicker } from '../datepicker/datepicker';
import { DATE_FORMAT_DD_MM_YYYY } from '../../utils/consts';

export const ModifyDates = () => {
    const dispatch = useAppDispatch();
    
    const [startDate, setStartDate] = useState<DateMoment>(moment().subtract(7, 'days'));
    const [endDate, setEndDate] = useState<DateMoment>(moment());

    const {
        studentIDs,
        timeFilter
    } = useAppSelector(
        rootState => rootState.filtersContainerSlice
    )

    const academicYear = timeFilter.academicYear as TimeFilterDetail
    const selected = timeFilter.selected as TimeFilterDetail
     
    const startDateString = startDate ? startDate.format(DATE_FORMAT_DD_MM_YYYY) : ''
    const endDateString = endDate ? endDate.format(DATE_FORMAT_DD_MM_YYYY) : ''
    const academicYearStartDate = moment(academicYear.startDate, DATE_FORMAT_DD_MM_YYYY)
    const academicYearEndDate = moment(academicYear.endDate, DATE_FORMAT_DD_MM_YYYY)

    const { mutation, mutate } = useCertificateSummaryReport(studentIDs, selected?.startDate, selected?.endDate);
    
    useEffect(() => {
        dispatch(updateDateTimeFilter({ startDate: startDateString, endDate: endDateString }))
        if (selected?.startDate !== '' && selected?.endDate !== '') {
            mutate()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDate, startDate])
    
    
    useEffect( () => {
        function handleMutation() {
            if (mutation.status === 'success' && mutation.data) {
                mutation.reset();
                const studentCertificates = mutation.data as StudentCertificates[]
                dispatch(setNewCertificates({studentCertificates}))
            } else if (mutation.status === 'error') {
                  mutation.reset();
            }
            if (mutation.status === 'loading') {
                dispatch(enableSkeleton())
            } else {
                dispatch(disableSkeleton())
            }
        }
        handleMutation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mutation]);
    
    return (
        <Datepicker
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            academicYearStartDate={academicYearStartDate}
            academicYearEndDate={academicYearEndDate}
        />
    );
};
