import { Button, Center, Divider, Flex, Text, useBoolean } from "@chakra-ui/react";
import { setActiveTab } from "../../containers/certificate-results/certificate-results.slice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { TABS } from "../../utils/enums";
import { t } from "@lingui/macro";
import { useEffect } from "react";

interface ButtonTabProps {
    tabTitle: string,
    isTabActive: boolean,
    onTabClick: () => void;
}

function ButtonTab({tabTitle, isTabActive, onTabClick}: ButtonTabProps) {
    return (
        <Button 
            variant={'unstyled'}
            width={'350px'}
            height={'48px'}
            boxShadow={isTabActive ? '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)' : ''}
            borderRadius={'40px'}
            bgColor={'#F4F8FA'}
            textAlign={'center'}
            color={isTabActive ? 'primary.600' : '#33475F'}
            fontWeight={'400px'}
            fontSize={'18px'}
            overflow='unset'
            isActive={isTabActive}
            _active={{
                bgColor: '#fff'
            }}
            onClick={onTabClick}
        >{tabTitle}</Button>
    )
}

export function CertificateTabs() {
    const dispatch = useAppDispatch()
    const [isNewTabActive, setIsNewTabActive] = useBoolean(true);
    const [isPastTabActive, setIsPastTabActive] = useBoolean(false);
    const handleNewClick = () => {
        setIsNewTabActive.on()
        setIsPastTabActive.off()
        dispatch(setActiveTab(TABS.newCertificates))
    }

    const handlePastClick = () => {
        setIsNewTabActive.off()
        setIsPastTabActive.on()
        dispatch(setActiveTab(TABS.pastCertificates))
    }
    const {
        newCertificates, pastCertificates
    } = useAppSelector(
        rootState => rootState.certificateResultsSlice
    )

    const hasBothNewAndPastCertificates = newCertificates.total.count > 0 && pastCertificates.total.count > 0
    const hasNoCertificates = newCertificates.total.count === 0 && pastCertificates.total.count === 0
    
    useEffect(() => {
        if (newCertificates.total.count === 0 && pastCertificates.total.count > 0) {
            handlePastClick()
        } else {
            handleNewClick()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newCertificates.total, pastCertificates.total])

    return (
        <>
            {hasBothNewAndPastCertificates ?
                <Flex
                    alignSelf={'center'}
                    bgColor={'#F4F8FA'}
                    border='1px solid #EDF1F4'
                    borderRadius={'40px'}
                    w={'700px'}
                    margin={{sm:'20px', xxl: '30px'}}
                    p={'4px'}
                >
                    <ButtonTab tabTitle={t`certificates.new`} isTabActive={isNewTabActive} onTabClick={handleNewClick} />
                    <ButtonTab tabTitle={t`certificates.past`} isTabActive={isPastTabActive} onTabClick={handlePastClick} />
                </Flex>
                :
                    <Center alignSelf={'center'}>
                        <Divider w={'125px'}/>
                        <Text 
                            textAlign={'center'}
                            color={'neutral.600'}
                            fontWeight={'400px'}
                            fontSize={'18px'}
                            p={'48px'}
                        >
                            { hasNoCertificates || newCertificates.total.count > 0 ? t`certificates.new` : t`certificates.past` }
                        </Text>
                        <Divider w={'125px'}/>
                    </Center>
            }
        </>
        
    )
}