import { Circle } from "@chakra-ui/react";
import { NotAllowedIcon } from '@chakra-ui/icons'

export function NotAllowedToastIcon() {
    return (
        <Circle size={6} background={'linear-gradient(136deg, #FA7E46 0%, #CC461B 100%)'} m={'0 2px'}>
            <NotAllowedIcon color={'white'} height={'16px'} width={'16px'}/>
        </Circle>

    )
}