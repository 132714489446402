import { HStack } from "@chakra-ui/react";
import { Tile } from "./rank-tile";
import RibbonBronze from "../../assets/ribbonBronze.svg"
import RibbonSilver from "../../assets/ribbonSilver.svg"
import RibbonGold from "../../assets/ribbonGold.svg"
import { useAppSelector } from "../../store/store";
import { t } from "@lingui/macro";

export function PastCertificateTiles() {

    const {
        pastCertificates
    } = useAppSelector(
        rootState => rootState.certificateResultsSlice
    )
    const {
        bronze,
        silver,
        gold } = pastCertificates
    
    return (
        <HStack justifyContent={'center'} width={'100%'}>
            <Tile name={t`certificate.level.bronze`} image={RibbonBronze} count={bronze.count} type={1} />
            <Tile name={t`certificate.level.silver`} image={RibbonSilver} count={silver.count} type={2} />
            <Tile name={t`certificate.level.gold`} image={RibbonGold} count={gold.count} type={3} />
        </HStack>
    )
} 