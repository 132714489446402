import { GridRow } from "./grid-row"
import { CheckBoxProps, RankDetailProps, StudentRowProps } from "./grid-types"
import { useAppDispatch, useAppSelector } from "../../store/store"
import { addIdentifiersToDownload, removeIdentifiersToDownload } from "../../containers/download/download-container.slice"
import { getCertificatesCount } from "./__helpers__/result-grid-helper"
import { CERTIFICATE_ROW_VARIANT } from "../../utils/enums"
import { Identifier } from "../../api/queries/certificate-reporting/api-types"

export function StudentRow({
    student,
    classId,
    groupId,
    index,
    groupIndex,
    classIndex,
    checkedItems,
    setCheckedItems }: StudentRowProps) {
    const dispatch = useAppDispatch()

    const {
        studentCertificates
    } = useAppSelector(
        rootState => rootState.certificateResultsSlice
    )

    function onCheckBoxChange(e: any) {
        setCheckedItems([
            ...checkedItems.slice(0, index),
            e.target.checked,
            ...checkedItems.slice(index + 1)
        ])

        const identifiers  = rankDetailProps().total.identifiers as Identifier[]
        const totalIdentifiers = identifiers.flatMap(item => item.identifiers)
        if (e?.target?.checked) {
            dispatch(addIdentifiersToDownload({ totalIdentifiers }))
        } else {
            dispatch(removeIdentifiersToDownload({ totalIdentifiers }))
        }
    }

    const checkBoxProps = () => ({
        checkBoxSize: 'md',
        checkBoxValue: `${classId}_${groupId}_${student.userId}`,
        isChecked: checkedItems[index],
        onCheckBoxChange
    } as CheckBoxProps)

    const rankDetailProps = () => ({
        ...getCertificatesCount([student.userId], studentCertificates) as RankDetailProps
    })

    const gridRowProps = () => ({
        name: student.name,
        id: student.userId,
        rowType: CERTIFICATE_ROW_VARIANT.student,
        isLastRecord: checkedItems.length -1 === index,
        isRowExpanded: true,
        checkBoxProps: checkBoxProps(),
        rankDetailProps: rankDetailProps()
    })
    
    return (
        <>
            <GridRow {...gridRowProps()}/>
        </>
    )
}