import { Center, Image, Text } from "@chakra-ui/react";
import EmptyState from "../../assets/empty-state.svg";
import { t } from "@lingui/macro";

export function EmptyCertificatesState() {
    return (
        <Center gap={'24px'} marginBottom={'30px'}>
            <Image src={EmptyState}/>
            <Text maxW={'200px'} fontSize={'24px'}>{t`certificates.emptyState`}</Text>
        </Center>
    )
}