import { createStandaloneToast } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { useState, useEffect } from "react";
import { useEnvConfig } from "../../env-config";
import { useDownload } from "../../hooks/use-download";
import { useAppDispatch } from "../../store/store";
import { DropdownOption, DropdownValue, RightIconProps } from "../dropdown/dropdown.types";
import { BatchModalProps } from "./download-types";
import { useGetDownloadReport } from "../../api/queries/certificate-reporting/new-certificates/use-get-download";
import { displayDownloadErrorToast } from "../toasts/toasts";
import { clearCheckedItems, clearIdentifiersToDownload } from "../../containers/download/download-container.slice";
import { usePdfName } from "./use-pdf-name";
import { CheckIcon } from "@chakra-ui/icons";
import { CommonBatchModal } from "./common-batch-modal";

export function BatchModal({name='', count, isOpen, identifiers, onClose, fullColor=false}: BatchModalProps) {
    const [ selectedBatchIndex, setSelectedBatchIndex ] = useState(1)
    const [ requestIdentifier, setRequestIdentifier] = useState('')
    const [ batches, setBatches ] = useState<Array<string[]>>([])
    const [ selectedBatchIdentifiers, setSelectedBatchIdentifiers ] = useState(identifiers)
    const { mutation, mutate } = useDownload(selectedBatchIdentifiers, fullColor);
    const { toast } = createStandaloneToast();
    const dispatch = useAppDispatch()
    
    const { DOWNLOAD_LIMIT } = useEnvConfig();
    const pdfName = usePdfName(name, selectedBatchIdentifiers.length, false, selectedBatchIndex)
    const downLoadQuery = useGetDownloadReport(requestIdentifier !== '' && requestIdentifier !== 'done', requestIdentifier, pdfName)
    const batchText = t`certificate.batch`
    const [downloadedIndexes, setDownloadedIndexes] = useState<Array<number>>([])
  
    if (downLoadQuery.isSuccess) {
        setRequestIdentifier('done')
        const indexes = downloadedIndexes.concat(selectedBatchIndex)
        setDownloadedIndexes(indexes)
    }
    
    if (downLoadQuery.isError) {
        toast.close('genericErrorToast')
        displayDownloadErrorToast()
    }

    function onDownloadClick() {
        mutate()
    }

    function onBatchModalClose() {
        dispatch(clearCheckedItems())
        dispatch(clearIdentifiersToDownload())
        onClose()
    }
    
    useEffect( () => {
        function handleMutation() {
            if (mutation.status === 'success' && mutation.data) {
                const requestData = mutation.data as { data: string }
                if (requestData) {
                    setRequestIdentifier(requestData?.data)
                }
                mutation.reset();
            } else if (mutation.status === 'error') {
                mutation.reset();
                toast.close('genericErrorToast')
                displayDownloadErrorToast()
                setRequestIdentifier('')
            }
        }
      handleMutation();
      // eslint-disable-next-line
    }, [mutation]);

    useEffect(() => {
        if (identifiers.length > DOWNLOAD_LIMIT) {
            setBatches(splitBatches(identifiers))
        }
    // eslint-disable-next-line
    }, [identifiers, setBatches, isOpen, DOWNLOAD_LIMIT])

    useEffect(() => {
        if (batches.length > 0) {
            setSelectedBatchIndex(1)
            setSelectedBatchIdentifiers(batches[0])
        }
    }, [batches])

    function splitBatches(identifiers: string[]) {
        const chunks: string[][] = [];
        identifiers.forEach((identifier, index) => {
            const chunkIndex = Math.floor(index / DOWNLOAD_LIMIT);
            if (!chunks[chunkIndex]) {
                chunks[chunkIndex] = [];
            }
            chunks[chunkIndex].push(identifier);
        });
        return chunks;
    }

    function getBatchOptions() {
        return batches?.map((batch, index) => {
            const batchValue = index + 1
            const option: DropdownOption = {
                value: batchValue,
                label: `${batchText} ${batchValue} (${batch.length})`
            }
            if (downloadedIndexes.includes(batchValue)) {
                option.rightIcon = ({
                    iconProps
                }: RightIconProps) => (
                    <CheckIcon {...iconProps}/>
                )
            }
            return option
        }) || []
    }

    function onBatchChange(value: DropdownValue) {
        const selectedBatchIndex = value as number
        setRequestIdentifier('')
        setSelectedBatchIndex(selectedBatchIndex)
        setSelectedBatchIdentifiers(batches[selectedBatchIndex - 1])
    }

    return (
        <CommonBatchModal
            isOpen={isOpen}
            onClose={onBatchModalClose}
            heading={`${count} ${name} ${count > 1 ? t`certificates` : t`certificate`}`}
            batchOptions={getBatchOptions()}
            selectedBatchIndex={selectedBatchIndex}
            onBatchChange={onBatchChange}
            onDownloadClick={onDownloadClick}
            isDownloading={downLoadQuery.isFetching || mutation.status === 'loading'}
            isDownloadComplete={requestIdentifier === 'done'}
        />
    )
}