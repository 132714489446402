import React, {
    forwardRef,
    useLayoutEffect,
    memo
} from 'react';

import {
    DropdownOption,
    DropdownValue
} from '../../dropdown.types';
import {
    getButtonProps,
    getTextWidth
} from './dropdown-list-item-helpers';
import * as styled from './dropdown-list-item.styled';

type DropdownListItemProps = {
    option: DropdownOption
    onOptionClick: (optionValue: DropdownValue) => void
    handleFocus: () => void
    isActive: boolean
    isBatchDropdown: boolean
};

const DropdownListItem = forwardRef(({
    option,
    onOptionClick,
    handleFocus,
    isActive,
    isBatchDropdown
}: DropdownListItemProps, ref) => {
    useLayoutEffect(() => {
        if (isActive) handleFocus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive]);

    return (
        <styled.container ref={ref} >
            <styled.wrapper
                variant={'unstyled'}
                display={'flex'}
                isDisabled={option.disabled}
                onClick={() => onOptionClick(option.value)}
                {...getButtonProps({
                    isActive,
                    option,
                    isBatchDropdown
                })}
            >
                <styled.text
                    {...getTextWidth({
                        isActive,
                        option
                    })}
                >
                    {option.label}
                </styled.text>
            </styled.wrapper>
        </styled.container>
    );
});

export default memo(DropdownListItem);
