import { Button, Box, Text, useDisclosure, Flex, Popover, PopoverTrigger, CircularProgress } from "@chakra-ui/react";
import { DownloadIcon } from '@chakra-ui/icons'
import { DownloadModal } from "../download/download-modal";
import { useAppSelector } from "../../store/store";
import { t } from "@lingui/macro";
import { useState } from "react";
import { TABS } from "../../utils/enums";

export function Footer() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [ isDownloading, setIsDownloading ] = useState(false)
    const [ isPopoverTriggered, setIsPopoverTriggered ] = useState(false)
    const { identifiersToDownload } = useAppSelector(rootState => rootState.downloadContainerSlice)

    const count = identifiersToDownload?.length
    const onPopoverOpen = () => {
        setIsPopoverTriggered(true)
        onOpen()
    }

    const {
        activeTab
    } = useAppSelector(
        rootState => rootState.certificateResultsSlice
    )

    if (activeTab !== TABS.newCertificates) {
        return (<></>)
    }

    return (
       <Flex boxShadow={'0px -2px 4px 0px rgba(17, 24, 39, 0.08)'}
            bg={'white'}
            justifyContent={'space-between'}
            padding={'15px'}
        >
            <Flex justifyContent={'flex-start'} align={'center'}>
                <Box display={'inline-flex'}> <Text fontWeight={'bold'} paddingRight={'5px'}>{identifiersToDownload.length}</Text>{count > 1 ? t`footer.certificatesSelected_plural` : t`footer.certificatesSelected`}</Box>
            </Flex>
            <Popover placement='top-start' onOpen={onPopoverOpen} isOpen={isOpen} onClose={onClose}>
                <PopoverTrigger>
                    <Button 
                        variant={'unstyled'}
                        isDisabled={count === 0}
                        w={'160px'}
                        h={'40px'}
                        display={'flex'}
                        justifyContent={'center'}
                        padding={'8px 32px'}
                        background={count > 0 ? 'linear-gradient(149deg, #00ABE8 4.33%, #007ACC 45.04%, #0063A7 100%)':'#EDF1F4'}
                        borderRadius={'40px'}
                        _disabled={{cursor: 'not-allowed', _hover: { bg: '#EDF1F4'}}}
                        opacity={isDownloading && isPopoverTriggered ? '70%' : 'inherit'}
                    >
                        { isDownloading && isPopoverTriggered? 
                            <CircularProgress isIndeterminate size={'16px'} />
                        : <>
                            <DownloadIcon color={count > 0 ? '#FFF' : '#838F9E'} height={'16px'} width={'16px'} mr={'10px'}/>
                            <Text color={count > 0 ? '#FFF' : '#5C6C7F'} fontSize={'16px'}>{t`download`}</Text>
                        </>
                        }
                       </Button>
                </PopoverTrigger>
                { identifiersToDownload && count > 0 && 
                <DownloadModal
                    variant={'popover'}
                    count={count}
                    isOpen={count > 0 && isOpen}
                    onClose={onClose}
                    identifiers={identifiersToDownload}
                    identifierStrings={identifiersToDownload}
                    setIsDownloading={setIsDownloading}
                />
                 }
            </Popover>
        </Flex>
    )
}