import React from 'react';

import { DropdownOption } from '../../dropdown.types';
import { DropdownCheck } from '../../dropdown-icons/dropdown-check';
import { Box } from '@chakra-ui/react';

type StyleParams = {
    option: DropdownOption
    isActive: boolean
    isBatchDropdown?: boolean
};

const getRightIcon = ({ isActive, option }: StyleParams) => {
    const iconDefaultProps = {
        w: '16px',
        h: '16px'
    };

    return isActive ? {
        ...option.rightIcon && {
            rightIcon: option.rightIcon({
                iconProps: {
                    color: 'primary.500',
                    ...iconDefaultProps
                }
            })
        }
    } : {
        ...option.rightIcon && {
            rightIcon: option.rightIcon({
                iconProps: {
                    color: 'neutral.400',
                    ...iconDefaultProps
                }
            })
        }
    };
};

export const getButtonProps = ({ isActive, option, isBatchDropdown }: StyleParams) => {
    return isActive ? {
        background: '#ECF9FE',
        color: '#0063A7',
        paddingLeft: '8px',
        leftIcon: isBatchDropdown ? <Box h={'12px'} w={'12px'} /> : <DropdownCheck h={'16px'} w={'16px'} />,
        justifyContent: 'flex-start',
        ...getRightIcon({
            isActive,
            option
        })
    } : {
        paddingLeft: '29px',
        justifyContent: 'flex-start',
        ...getRightIcon({
            isActive,
            option
        })
    };
};

export const getTextWidth = ({ isActive, option }: StyleParams) => {
    let defaultTextWidth = {
        base: 'calc(100% - 35px)',
        tablet: 'calc(100% - 40px)'
    };

    if (option.rightIcon) {
        defaultTextWidth = {
            base: 'calc(100% - 35px)',
            tablet: 'calc(100% - 40px)'
        };
    }

    if (isActive) {
        defaultTextWidth = {
            base: 'calc(100% - 55px)',
            tablet: 'calc(100% - 62px)'
        };
    }

    return {
        w: defaultTextWidth
    };
};
