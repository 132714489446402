import { useAppSelector } from "../../store/store"

export function useClassGroupYearName() {
    const {
        timeFilter,
        classroom,
        group
    } = useAppSelector(
        rootState => rootState.filtersContainerSlice
    )
    const selectedClassroom = classroom.selected?.name || ''
    const selectedGroup = group.selected?.name || ''
    const yearName = timeFilter.selected?.label || ''
    return `${selectedClassroom} | ${selectedGroup} | ${yearName}`
}