import {
    QueryCache,
    QueryClient,
    QueryClientProvider,
    MutationCache
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { PropsWithChildren } from 'react';

import { displayErrorToast } from '../../components/toasts/toasts';

// Prevent duplicate toast messages with basic debounce
const retryAttempts = 2;

const queryCache = new QueryCache({
    onError: (error) => {
       console.error(`Query Error - ${error}`);
       displayErrorToast();
    }
});

const mutationCache = new MutationCache({
    onError: (error) => {
        console.error(`Mutation Error - ${error}`);
        displayErrorToast();
    }
});



// Main Query configuration these values are different to the defaults,
// see comments for reasoning.
// @see https://tanstack.com/query/v4/docs/guides/important-defaults
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            
            // Default retry behaviour only twice 3 is unlikely to solve the issue,
            // try twice, wait on the delayMs exponential increase
            // @see https://tanstack.com/query/v4/docs/guides/query-retries
            retry: retryAttempts,
            retryDelay: (attemptIndex, error) => {
                const delayMs = Math.min(2000 * 2 ** attemptIndex, 30000);

                if (attemptIndex === retryAttempts) {
                    console.error('Max retry attempts reached, there maybe an issue with the Network.');

                    return delayMs;
                }

                console.error('Query default retry attempt:' + (attemptIndex + 1), error);
                console.info(`Retrying in ${delayMs}`);

                return delayMs;
            }
        }
    },
    queryCache,
    mutationCache
});

export function ApiProvider ({
    children, client = queryClient
}: PropsWithChildren<{
    client?: QueryClient
}>) {
    return (
        <>
            <QueryClientProvider client={client}>
                <>
                    { children }
                    {process.env.NODE_ENV === 'development' ? <ReactQueryDevtools initialIsOpen={false} /> : null}
                </>
            </QueryClientProvider>
        </>
    );
}
