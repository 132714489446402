/* eslint-disable @typescript-eslint/naming-convention */
//
// These can be used to have runtime conditionals in the code,
// eg log / do something different on dev env than prod.
// These are for the actual deployment environments in the cluster,
// they are updated in the docker CMD process when the container starts.

// This is for a local dev mode build, eg a CRA webpack dev build mode
export const __LOCAL__ = process.env.NODE_ENV !== 'production';

const _env_ = window._env_ || {};

export const __DEV_ENV__ = _env_.ENVIRONMENT === 'dev';

export const __QA_ENV__ = _env_.ENVIRONMENT === 'qa';

export const __PROD_ENV__ = _env_.ENVIRONMENT === 'prod';
