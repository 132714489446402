import { Td } from "@chakra-ui/react";

export function CellWrapper({count}: {count: number}) {
    return (
        <Td
            borderBottom={'1px solid #DBE0E6'}
            borderRight={'1px solid #DBE0E6'}
            textAlign={'center'}
            color={'#1A304B'}
            fontWeight={'700'}
        >
                { count || '-' }
        </Td>
    )
}