import { PropsWithChildren } from 'react';
import { EnvConfigProvider } from '../../env-config';
import { Compose } from '../../components/compose/compose';
import { ApiProvider } from '../../api/api-provider';
import { ReduxStoreProvider } from '../../store/redux-store-provider';

type Props = PropsWithChildren<unknown>;

const providers = [
    EnvConfigProvider,
    ApiProvider,
    ReduxStoreProvider
];

export const AppProviders = ({ children }: Props) => {
    return (
        <Compose wrappers={providers}>
            {children}
        </Compose>
    );
};
