import React, { PropsWithChildren } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { store as defaultStore } from './store';

export function ReduxStoreProvider ({
    children, store = null
}:PropsWithChildren<{ store?:any }>) {
    store = store || defaultStore;

    // @ts-ignore for debugging
    window.__store__ = store;

    return (
        <ReduxProvider store={store}>
            {children}
        </ReduxProvider>
    )
}
