import { useOutsideClick, HStack } from '@chakra-ui/react';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import 'react-dates/initialize';
import { FocusedInputShape } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import DateArrowIcon from './date-arrow-icon/date-arrow-icon';
import { DateInput } from './date-input/date-input';
import {
    DateMoment,
    DatepickerProps
} from './datepicker.types';
import { t } from "@lingui/macro";

type FocusedInput = FocusedInputShape | null;

export const Datepicker = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    academicYearStartDate,
    academicYearEndDate
}: DatepickerProps) => {
    const localStartDate = startDate && moment(startDate).local();
    const localEndDate = endDate && moment(endDate).local();
    const localAcademicYearStartDate = startDate && moment(academicYearStartDate).local();
    const currentMoment = moment().local();
    const stackRef = useRef<HTMLDivElement | null>(null);
    const [focusedInput, setFocusedInput] = useState<FocusedInput>(null);
    
    function getCurrentDateTime (date: DateMoment) {
        return moment(date).local().set({
            hour: currentMoment.hour(),
            minute: currentMoment.minute(),
            second: currentMoment.second()
        });
    }

    function onStartDateChangeHandler (date: DateMoment) {
        const isToday = currentMoment.isSame(date, 'day');

        if (isToday) {
            setStartDate(getCurrentDateTime(date));
        } else {
            setStartDate(moment(date).local().startOf('day'));
        }

        setFocusedInput(null);
    }

    function onEndDateChangeHandler (date: DateMoment) {
        const updatedDate = moment(date).local().endOf('day');

        setEndDate(updatedDate);
        setFocusedInput(null);
    }

    useOutsideClick({
        ref: stackRef,
        handler: () => setFocusedInput(null)
    });

    return (
        <HStack ref={stackRef} spacing={'5'} justifyContent={'center'}>
            <DateInput
                isHighlighted={focusedInput !== 'endDate' && !localStartDate}
                labelText={t`timePeriod.start.date`}
                singleDatePickerProps={{
                    date: localStartDate,
                    focused: focusedInput === 'startDate',
                    onDateChange: (date) => onStartDateChangeHandler(date),
                    isOutsideRange: (day) => day?.local().isAfter(moment()) || day?.local().isBefore(localAcademicYearStartDate, 'day'),
                    isDayHighlighted: (day: moment.MomentInput) => moment(day).isSameOrAfter(localStartDate) && moment(day).isSameOrBefore(localEndDate),
                    initialVisibleMonth: () => moment(startDate).local()
                }}
                onClick={() => setFocusedInput('startDate')}
            />
            <DateArrowIcon />
            <DateInput
                isHighlighted={!localEndDate}
                labelText={t`timePeriod.end.date`}
                singleDatePickerProps={{
                    date: localEndDate,
                    focused: focusedInput === 'endDate',
                    onDateChange: (date) => onEndDateChangeHandler(date),
                    isOutsideRange: (day) => day?.local().isAfter(moment()) || day?.local().isBefore(localStartDate, 'day'),
                    isDayHighlighted: (day: moment.MomentInput) => moment(day).isSameOrAfter(localStartDate) && moment(day).isSameOrBefore(localEndDate),
                    initialVisibleMonth: () => moment(endDate)
                }}
                onClick={() => setFocusedInput('endDate')}
            />
        </HStack>
    );
};
