export const dropdownButtonBorderRadius = '8px 8px 0 0px';

export const dropdownContainerWidth = {
    sm: '235px',
    xxl: '300px',
    xxxl: '355px'
};

export const dropdownContainerHeight = {
    base: '32px',
    tablet: '40px'
};

export const dropdownContainerFontSize = {
    base: 'xs',
    tablet: 'sm'
};

export const gridGap = {
    sm: '18px',
    xxl: '24px',
    xxxl: '28px'
};

export const toggleSwitchWidth = {
    sm: '130px',
    xlg: '140px',
    xxl: '165px',
    xxxl: '175px'
};
