/* eslint-disable max-len */
import { createIcon } from '@chakra-ui/icons';
import React from 'react';

export const DropdownCheck = createIcon({
    displayName: 'DropdownCheck',
    viewBox: '0 0 16 16',
    path: (
        <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd" d="M13.3661 4.23431C13.6785 4.54673 13.6785 5.05327 13.3661 5.36569L6.96608 11.7657C6.65366 12.0781 6.14713 12.0781 5.83471 11.7657L2.63471 8.56569C2.32229 8.25327 2.32229 7.74673 2.63471 7.43431C2.94712 7.1219 3.45366 7.1219 3.76608 7.43431L6.40039 10.0686L12.2347 4.23431C12.5471 3.9219 13.0537 3.9219 13.3661 4.23431Z" fill="#007ACC" fillRule="evenodd" />
        </svg>
    )
});
