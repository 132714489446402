import { isDesktop } from 'react-device-detect';

type OnScrollToParams = {
    ref: HTMLElement
    top?: number
    left?: number
};

type OnScrollIntoViewParams = {
    ref: HTMLElement
    block?: ScrollLogicalPosition
};

export const useScrollBehavior = () => {
    const onScrollTo = ({
        ref,
        top = 0,
        left = 0
    }: OnScrollToParams) => {
        ref.scrollTo({
            top,
            left,
            behavior: isDesktop ? 'smooth' : 'auto'
        });
    };
    const onScrollIntoView = ({
        ref,
        block = 'start'
    }: OnScrollIntoViewParams) => {
        ref.scrollIntoView({
            block,
            behavior: isDesktop ? 'smooth' : 'auto'
        });
    };

    return {
        onScrollTo,
        onScrollIntoView
    };
};
