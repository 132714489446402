import { useQuery } from '@tanstack/react-query';
import { useAuthToken, useFetcher } from "./use-fetcher";
import { useEnvConfig } from '../../../env-config';

export function useGetPastYearFilters (enabled: boolean) {

    const { SCHOOL_ACADEMIC_YEAR_GATEWAY, NO_OF_PAST_YEARS } = useEnvConfig();
    const fetchPastYearFilters = useFetcher(
        SCHOOL_ACADEMIC_YEAR_GATEWAY,
        `school-academic-years/?pastYearsCount=${NO_OF_PAST_YEARS}`,
        "GET",
        {headers: {AuthToken : useAuthToken()}}
    );

    return useQuery(
        ["GetPastYearFilters"], 
        fetchPastYearFilters,
        { 
            refetchOnWindowFocus: false,
            enabled,
            staleTime: Infinity
        }
    );
}
