import { TimeFilterDetail, Classroom, Group } from "../api/queries/certificate-reporting/api-types";
import { usePastSubmitDownloadRequest } from "../api/queries/certificate-reporting/past-certificates/use-submit-download";
import { useAppSelector } from "../store/store";

export function usePastDownload(
    type: number,
    batchSize?: number,
    batchNumber?: number) {

        const {
            timeFilter,
            classroom,
            group,
        } = useAppSelector(
            rootState => rootState.filtersContainerSlice
        )
        const selectedTimeFilter = timeFilter.selected as TimeFilterDetail
        const selectedClassroom = classroom.selected as Classroom
        const selectedGroup = group.selected as Group
    
        const startDate = selectedTimeFilter.startDate
        const endDate = selectedTimeFilter.endDate
        const classroomId = selectedClassroom.id
        const groupId = selectedGroup.id
    const mutation = usePastSubmitDownloadRequest(
        startDate,
        endDate,
        classroomId,
        groupId,
        type,
        batchSize || 0,
        batchNumber || 0);

    return {mutation, mutate: () => mutation.mutate({
        startDate,
        endDate,
        classroomId,
        groupId,
        type,
        batchSize,
        batchNumber})};
}
