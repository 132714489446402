import {
    chakra,
    HStack,
    Text,
    VStack
} from '@chakra-ui/react';

export const dateInput = chakra(HStack, {
    label: 'datepicker-input',
    baseStyle: {
        '.datepicker-highlight': { backgroundColor: 'blue' },
        '.SingleDatePickerInput': {
            border: '0',
            width: '260px',
            height: '52px'
        },
        '.SingleDatePicker_picker': {
            borderRadius: '12px'
        },
        '.DateInput_input': {
            width: '260px',
            height: '52px',
            borderRadius: '12px',
            border: '1px solid',
            borderColor: '#CBD5E0',
            color: '#4A5568',
            textAlign: 'center',
            cursor: 'pointer',
            transition: 'background-color 0.5s ease',
            outline: 'none',
            padding: '0'
        },
        '.DateInput_input__focused': {
            border: '2px solid',
            borderColor: 'primary.500',
            color: '#1A304B'
        },
        '.DateInput_input: hover': {
            backgroundColor: '#F2F5F7'
        },
        '.CalendarDay__highlighted_calendar': {
            background: '#e4e7e7',
            color: '#484848'
        },
        '.CalendarDay__selected': {
            background: '#718096',
            border: '1px solid',
            borderColor: '#718096',
            color: '#fff'
        },
        '.CalendarDay__selected:hover': {
            background: '#4A5568',
            border: '1px solid',
            borderColor: '#4A5568',
            color: '#fff'
        },
        '.DayPicker_transitionContainer': {
            paddingBottom: '37px',
            boxSizing: 'content-box'
        }
    }
});

export const text = chakra(Text, {
    label: 'datepicker-text',
    baseStyle: {
        color: '#4A5568',
        fontSize: 'sm',
        letterSpacing: '1px',
        lineHeight: '21px'
    }
});

export const stack = chakra(VStack, {
    label: 'datepicker-stack',
    baseStyle: {
        '&.datepicker-highlight': {
            '.DateInput_input': {
                border: '1px solid',
                borderColor: 'primary.500'
            },
            '.DateInput_input__focused': {
                border: '2px solid',
                borderColor: 'primary.500',
                color: '#1A304B'
            }
        }
    }
});
