import { useQuery } from '@tanstack/react-query';
import { useAuthToken, useFetcher } from "../use-fetcher";
import { useEnvConfig } from '../../../../env-config';

export function useGetDownloadReport (enabled: boolean = false, requestIdentifier: string, pdfName: string) {
const { CERTIFICATE_REPORT_GATEWAY } = useEnvConfig();

   const fetchDownloadPDF = useFetcher(
        CERTIFICATE_REPORT_GATEWAY,
        `report/download?requestIdentifier=${requestIdentifier}`,
        "GET",
        {headers: {AuthToken : useAuthToken()}});

    const downLoadQuery = useQuery(
        [Blob, "GetDownload", requestIdentifier], 
        fetchDownloadPDF, 
        { 
            refetchOnWindowFocus: false,
            enabled: enabled && requestIdentifier !== '',
            onSuccess: (data) => {
                if (data && enabled) {
                    const href = window.URL.createObjectURL(data as Blob);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', pdfName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }  
            }
        },
    );

    return downLoadQuery
}
