import {
    Button,
    chakra,
    Text
} from '@chakra-ui/react';

export const button = chakra(Button, {
    baseStyle: {
        paddingInlineStart: '2',
        paddingInlineEnd: '2'
    }
});

export const text = chakra(Text, {
    baseStyle: {
        display: 'block',
        fontWeight: 400
    }
});
