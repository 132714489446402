import {
    Popover
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { DropdownList } from './dropdown-list/dropdown-list';
import { DropdownSelect } from './dropdown-select/dropdown-select';
import {
    DropdownProps,
    DropdownValue,
    DropdownVariant
} from './dropdown.types';

export const Dropdown = ({
    options,
    value,
    onOptionClick,
    label,
    variant = DropdownVariant.halfStroke,
    labelConstant = null,
    dividerIndex,
    isBatchDropdown = false
}: DropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const onOptionClickHandler = (value: DropdownValue) => {
        setIsOpen(false);
        setTimeout(() => {
            onOptionClick(value);
        }, 101);
    };

    return (
        <Popover
            isOpen={isOpen}
            isLazy
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
            strategy={'fixed'}
            matchWidth
        >
            <DropdownSelect
                isOpen={isOpen}
                label={label}
                labelConstant={labelConstant}
                options={options}
                variant={variant}
            />
            <DropdownList
                options={options}
                value={value}
                onOptionClick={onOptionClickHandler}
                dividerIndex={dividerIndex}
                isBatchDropdown={isBatchDropdown}
            />
        </Popover>
    );
};
