import moment from 'moment';
import { t } from "@lingui/macro";
import { SingleDatePicker } from 'react-dates';

import * as styled from '../datepicker.styled';
import { DateInputProps } from './date-input.types';

export const DateInput = ({
    labelText,
    onClick,
    singleDatePickerProps,
    isHighlighted = false
}: DateInputProps) => {
    const isToday = moment().isSame(singleDatePickerProps.date, 'day');
    const onFocusChangeHandler = () => {
        //unused required prop
    };

    return (
        <styled.stack
            align={'stretch'}
            className={isHighlighted ? 'datepicker-highlight' : ''}
            spacing={'2'}
        >
            <styled.text>{labelText}</styled.text>
            <styled.dateInput onClick={onClick}>
                <SingleDatePicker
                    {...singleDatePickerProps}
                    displayFormat={isToday ? '[' + t`timePeriod.today` + ']' : 'DD MMM YYYY'}
                    hideKeyboardShortcutsPanel={true}
                    id={labelText}
                    placeholder={t`timePeriod.set.date`}
                    readOnly={true}
                    weekDayFormat={'ddd'}
                    onFocusChange={onFocusChangeHandler}
                />
            </styled.dateInput>
        </styled.stack>
    );
};
