import { InfoOutlineIcon } from '@chakra-ui/icons'
import { 
    Circle, 
    IconButton, 
    Stack, 
    useDisclosure, 
    Text, 
    Drawer, 
    DrawerBody, 
    DrawerFooter, 
    DrawerHeader, 
    DrawerOverlay, 
    DrawerContent, 
    DrawerCloseButton    
} from "@chakra-ui/react"
import { t } from "@lingui/macro";

export function InfoPanel() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    
    return (
        <>
            <IconButton outline={0} bg={'transparent'} aria-label={t`info.panel.header`} isRound={true} onClick={onOpen} _hover={{bg:'transparent'}}> 
                <Circle size={10} backgroundColor={'white'}><InfoOutlineIcon color={'primary.600'} height={'20px'} width={'20px'}/></Circle>
            </IconButton>
            <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='lg'>
                <DrawerOverlay />
                <DrawerContent backgroundColor={'white'} lineHeight={'28px'} letterSpacing={'-0.2px'}>
                    <DrawerCloseButton />
                    <DrawerHeader p={'40px'} borderBottomWidth='1px' fontSize={'40px'} fontWeight={'400'}>
                        {t`info.panel.header`}
                    </DrawerHeader>
                    <DrawerBody p={0}>
                        <Stack spacing={3} p={'40px'} >
                            <Text mb={'32px'}>{t`info.panel.p1`}</Text>
                            <Text mb={'32px'}>{t`info.panel.p2`}</Text>
                            <Text mb={'54px'} as='b'>{t`info.panel.p3`}</Text>
                            <Text fontWeight={"400"} fontSize='34px'>{t`certificates.past`}</Text>
                            <Text mt={'5px'}>{t`info.panel.p4`}</Text>
                        </Stack>
                    </DrawerBody>
                    <DrawerFooter />
                </DrawerContent>
            </Drawer>
        </>
    )
}