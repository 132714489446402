import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit';
import { PastCertificatesSummary, RankDetail, StudentCertificates } from '../../api/queries/certificate-reporting/api-types';
import { RANK_TYPE, TABS } from '../../utils/enums';
import { getCertificateObject } from '../../components/result-grid/__helpers__/result-grid-helper';

const initialRankState: RankDetail = {
    count: 0,
    identifiers: []
}
interface NewCertificatesTileState {
    [RANK_TYPE.bronze]: RankDetail,
    [RANK_TYPE.silver]: RankDetail,
    [RANK_TYPE.gold]: RankDetail,
    [RANK_TYPE.platinum]: RankDetail,
    [RANK_TYPE.emerald]: RankDetail,
    [RANK_TYPE.diamond]: RankDetail,
    [RANK_TYPE.epic]: RankDetail,
    [RANK_TYPE.legend]: RankDetail,
    'total': RankDetail
}
interface PastCertificatesTileState {
    [RANK_TYPE.bronze]: RankDetail,
    [RANK_TYPE.silver]: RankDetail,
    [RANK_TYPE.gold]: RankDetail,
    'total': RankDetail
}
interface CertificatesResultsState {
    studentCertificates: StudentCertificates[],
    activeTab: TABS,
    newCertificates: NewCertificatesTileState,
    pastCertificates: PastCertificatesTileState,
    isSummaryLoading: boolean
}

const newCertificatesInitialState: NewCertificatesTileState = {
    [RANK_TYPE.bronze]: initialRankState,
    [RANK_TYPE.silver]: initialRankState,
    [RANK_TYPE.gold]: initialRankState,
    [RANK_TYPE.platinum]: initialRankState,
    [RANK_TYPE.emerald]: initialRankState,
    [RANK_TYPE.diamond]: initialRankState,
    [RANK_TYPE.epic]: initialRankState,
    [RANK_TYPE.legend]: initialRankState,
    'total': initialRankState
}

const pastCertificatesInitialState: PastCertificatesTileState = {
    [RANK_TYPE.bronze]: initialRankState,
    [RANK_TYPE.silver]: initialRankState,
    [RANK_TYPE.gold]: initialRankState,
    'total': initialRankState
}

const initialState: CertificatesResultsState = {
    studentCertificates: [],
    activeTab: TABS.newCertificates,
    newCertificates: newCertificatesInitialState,
    pastCertificates: pastCertificatesInitialState,
    isSummaryLoading: false
}

interface StudentCertificatesActionType {
    studentCertificates: StudentCertificates[]
}
interface PastCertificatesActionType {
    pastCertificateSummary: PastCertificatesSummary
}

const certificateResultsSlice = createSlice({
    name: 'certificateResultsSlice',
    initialState,
    reducers: {
        setNewCertificates(state: CertificatesResultsState, action: PayloadAction<StudentCertificatesActionType>) {
            const certificates = action.payload.studentCertificates as StudentCertificates[]
            state.studentCertificates = certificates
            const certificateObject = getCertificateObject(certificates)
            state.newCertificates = certificateObject
        },
        setActiveTab(state: CertificatesResultsState, action: PayloadAction<TABS>) {
            state.activeTab = action.payload
        },
        setPastCertificates(state: CertificatesResultsState, action: PayloadAction<PastCertificatesActionType>) {
            state.pastCertificates = {
                [RANK_TYPE.bronze]: { count: action.payload.pastCertificateSummary.bronzeCount, identifiers:[] },
                [RANK_TYPE.silver]: { count: action.payload.pastCertificateSummary.silverCount, identifiers:[] },
                [RANK_TYPE.gold]: { count: action.payload.pastCertificateSummary.goldCount, identifiers:[] },
                'total': { count: action.payload.pastCertificateSummary.bronzeCount 
                    + action.payload.pastCertificateSummary.silverCount 
                    + action.payload.pastCertificateSummary.goldCount, identifiers:[] }
            }
        },
        enableSkeleton(state: CertificatesResultsState) {
            state.isSummaryLoading = true
        },
        disableSkeleton(state: CertificatesResultsState) {
            state.isSummaryLoading = false
        }
    }
});

export const { 
    setNewCertificates,
    setActiveTab,
    setPastCertificates,
    enableSkeleton,
    disableSkeleton
} = certificateResultsSlice.actions;

export default certificateResultsSlice;

