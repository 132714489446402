import {
    Button,
    chakra,
    Text,
    ListItem
} from '@chakra-ui/react';

import { dropdownContainerFontSize } from '../../dropdown.styled';

export const container = chakra(ListItem, {
    label: 'dropdown-list-item-container',
    baseStyle: {
        margin: '4px 0',
        lineHeight: '24px',
        overflow: 'hidden'
    }
});

export const wrapper = chakra(Button, {
    label: 'dropdown-list-item-wrapper',
    baseStyle: {
        width: '100%',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: 0,
        bgColor: 'inherit',
        whiteSpace: 'normal',
        fontWeight: 400,
        textAlign: 'left',
        fontSize: dropdownContainerFontSize,
        paddingInlineEnd: '1',
        minHeight: '40px',
        paddingTop: {
            base: '8px',
            tablet: '12px'
        },
        paddingBottom: {
            base: '8px',
            tablet: '12px'
        },
        _hover: { bgColor: '#F2F5F7', color: '#0063A7' },
        _disabled: {
            opacity: 0.5,
            pointerEvents: 'none'
        }
    }
});

export const text = chakra(Text, {
    label: 'dropdown-list-item-text',
    baseStyle: {
        padding: '0'
    }
});
