import { useAuthToken, useFetcher } from "../use-fetcher";
import { useEnvConfig } from '../../../../env-config';
import { useQuery } from '@tanstack/react-query';
import { PRODUCT_ID } from "../../../../utils/consts";

export function usePastCertificateSummaryQuery (
    startDate: string,
    endDate: string,
    classroomId: number,
    groupId: number,
    enabled: boolean = false
    ) {
    const { CERTIFICATE_GATEWAY } = useEnvConfig();

    const fetchCertificateSummary = useFetcher(
        CERTIFICATE_GATEWAY,
        `certificates/summary?productId=${PRODUCT_ID}&startDate=${startDate}&endDate=${endDate}&classroomId=${classroomId}&groupId=${groupId}`,
        "GET",
        {headers: {AuthToken : useAuthToken()}});

        return useQuery(
            ["PastCertificates", startDate, endDate, classroomId, groupId],
            fetchCertificateSummary, 
            { 
                refetchOnWindowFocus: false,
                enabled
            }
        );
}
