
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"
import { alertAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);


const customSuccess = definePartsStyle({
    container: {
        backgroundColor: '#9BE49D',
        color: '#001937',
        fontFamily: 'poppins',
        fontSize: '16px',
        borderRadius: '12px',
        textAlign: 'center',
        alignSelf: 'center',
        marginTop: '70px'
    },
    icon: {
        svg: {
            path: {
                fill: '#0D6830'
            }
        }
    }
});

const customError = definePartsStyle({
    container: {
      fontFamily: 'poppins',
      border: '2px solid',
      borderRadius: '12px',
      borderColor: '#FEF6F7',
      background: 'F0939B',
      
    }
});

const genericError = definePartsStyle({
    container: {
      fontFamily: 'poppins',
      border: '2px solid #FEF6F7',
      bg: '#F0939B',
      borderRadius: '15px',
      boxShadow: '0px 2px 4px -1px rgba(0, 25, 55, 0.06), 0px 4px 6px -1px rgba(0, 25, 55, 0.10)'
    }
})

export const alertTheme = defineMultiStyleConfig({
  variants: { customSuccess, customError, genericError },
});