export enum RANK_TYPE {
    bronze = 'bronze',
    silver = 'silver',
    gold = 'gold',
    platinum = 'platinum',
    emerald = 'emerald',
    diamond = 'diamond',
    epic = 'epic',
    legend = 'legend'
}

export enum DATE_FILTER_TYPE {
    CurrentAcademicYear = 'CurrentAcademicYear',
    ThisWeek = 'ThisWeek',
    LastWeek = 'LastWeek',
    DateRange = 'DateRange',
    PreviousAcademicYear = "PreviousAcademicYear",
    PastYears = "PastYears"
}

export enum TABS {
    newCertificates = 'newCertificates',
    pastCertificates = 'pastCertificates'
}

export enum CERTIFICATE_ROW_VARIANT {
    class = 'class',
    group = 'group',
    student = 'student'
}