import { Box, Flex } from "@chakra-ui/react";
import { Dropdown } from "../dropdown/dropdown";
import { Classroom } from "../../api/queries/certificate-reporting/api-types";
import { useAppSelector } from "../../store/store";
import { DropdownValue } from "../dropdown/dropdown.types";
import { dropdownContainerWidth } from "../dropdown/dropdown.styled";

interface ClassFilterProps {
    onClassChange : (value: DropdownValue) => void
}

export function ClassFilter({ onClassChange } : ClassFilterProps) {

    const {
        classroom,
    } = useAppSelector(
        rootState => rootState.filtersContainerSlice
    )
    const classRoomData = classroom.data as Classroom[]
    function getClassRoomOptions() {
        return classRoomData?.map(({
            id,
            name
        }) => ({
            value: id,
            label: name,
        })) || [];
    }

    return (
        <Flex
            h={'auto'}
            w={'auto'}
        >
            <Flex w={'100%'} alignItems={'center'} position={'relative'}>
                <Box width={'28px'} height={'24px'} border={'2px solid'} borderColor={'secondary.300'} flexShrink={0}/>
                <Box width={'100%'} maxW={dropdownContainerWidth}>
                    <Dropdown
                        options={getClassRoomOptions()}
                        value={classroom.selected?.id}
                        onOptionClick={onClassChange}
                        label={classroom.selected?.name || ''}
                    />
                </Box>
                
            </Flex>
        </Flex>
    )
}