import { Text, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, ModalFooter } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { Dropdown } from "../dropdown/dropdown";
import { DropdownVariant } from "../dropdown/dropdown.types";
import { ModalPrintButton } from "./print-buttons";
import { CommonBatchModalProps } from "./download-types";
import { useEnvConfig } from "../../env-config";

export function CommonBatchModal({
    isOpen,
    onClose,
    heading,
    batchOptions,
    onBatchChange,
    selectedBatchIndex,
    onDownloadClick,
    isDownloading,
    isDownloadComplete
    } : CommonBatchModalProps) {
    const { DOWNLOAD_LIMIT } = useEnvConfig();
    const batchMessage = t`batch.message`.replaceAll('100', DOWNLOAD_LIMIT.toString())
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
            <ModalOverlay />
            <ModalContent borderRadius={'20px'} maxW={'484px'}>
            <ModalHeader 
                fontSize={'32px'}
                fontWeight={'400'}
                pl={'40px !important'}
                bg={'linear-gradient(0deg, #62D6F8 0%, #62D6F8 100%)'}
                borderRadius={'16px 16px 0px 0px'}
            >
                        {heading}
            </ModalHeader>
            <ModalCloseButton m={'15px'} borderRadius={'full'} bg={'rgba(255, 255, 255, 0.48)'}/>
            <ModalBody fontSize={'18px'} fontWeight={'400'} padding={'32px !important'}>
                { batchOptions && batchOptions.length > 0 && 
                    <Text>{batchMessage}</Text>
                }
                <Flex direction={'column'} justifyContent={'center'} m={'20px 0'}>

                { batchOptions && batchOptions.length > 0 && 
                <Box w={'300px'} alignSelf={'center'}>
                    <Dropdown
                        options={batchOptions}
                        value={selectedBatchIndex}
                        onOptionClick={onBatchChange}
                        label={batchOptions[selectedBatchIndex-1].label}
                        variant={DropdownVariant.fullStroke}
                        dividerIndex={'all'}
                        isBatchDropdown
                    />
                </Box>}

                </Flex> 
            </ModalBody>
            <ModalFooter justifyContent={'center'} mb={'32px'} p={0}>
                <ModalPrintButton
                    onClick={onDownloadClick}
                    name={t`download`}
                    isDownloading={isDownloading}
                    isDownloadComplete={isDownloadComplete}
                />
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}