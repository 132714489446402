import { Classroom, Group, TimeFilterDetail } from "../../../api/queries/certificate-reporting/api-types"
import { t } from '@lingui/macro' 
import { DATE_FILTER_TYPE } from "../../../utils/enums"

export function getStudentIDsAllClasses(classRooms: Classroom[]) {
    let studentIDs: string[] = []
    classRooms.forEach(
        classRoom => studentIDs.push(...getStudentIDsAtClassLevel(classRoom))
    )
    return studentIDs
}

export function getStudentIDsAtClassLevel(classRoom: Classroom) {
    let studentIDs: string[] = []
    classRoom.groups.forEach(
        group => studentIDs.push(...getStudentIDsAtGroupLevel(group))
    )
    return studentIDs
}

export function getStudentIDsAtGroupLevel (group: Group) {

    const studentIDs = group.students.map(
        student => student.userId)
    return studentIDs
}

export function getAllGroups(){
    return {id: 0, name: t`groups.all`, students: []}    
}

export function getAllClasses(){
    return {id: 0, name: t`classes.all`, groups: [getAllGroups()]}
}

export function getDateTimeFilters(dateTimeFilters: TimeFilterDetail[]) {
    return dateTimeFilters.filter(timeFilter => 
        timeFilter.filterType !== DATE_FILTER_TYPE.PreviousAcademicYear)
        .map(timeFilter => ({
        ...timeFilter,
        label: getLabel(timeFilter.filterType, timeFilter.label)
    }))
}

export function getLabel(filterType : DATE_FILTER_TYPE | string, label: string){    
    if(filterType === DATE_FILTER_TYPE.CurrentAcademicYear) return t`dateFilterType.CurrentAcademicYear`;
    if(filterType === DATE_FILTER_TYPE.DateRange) return t`dateFilterType.DateRange`;
    if(filterType === DATE_FILTER_TYPE.LastWeek) return t`dateFilterType.LastWeek`;
    if(filterType === DATE_FILTER_TYPE.ThisWeek) return t`dateFilterType.ThisWeek`;
    if(filterType === DATE_FILTER_TYPE.PreviousAcademicYear) return t`dateFilterType.PreviousAcademicYear`;
    return label;
}

export function getPastYearFilters(dateTimeFilters: TimeFilterDetail[]) {
    return dateTimeFilters.map((timeFilter, index) => {
        return {
        ...timeFilter,
        filterType: `${timeFilter.filterType}-${index}`
    }
})
}