import { Popover, PopoverTrigger, Button, PopoverContent, Text, VStack, Checkbox, PopoverBody, Box, Flex, Divider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DropdownChevronUp } from "../dropdown/dropdown-icons/dropdown-chevron-up";
import { DropdownChevronDown } from "../dropdown/dropdown-icons/dropdown-chevron-down";
import convertToRoman from "./helpers/convert-to-roman";
import { Identifier } from "../../api/queries/certificate-reporting/api-types";
import { t } from "@lingui/macro";

interface RankDropdownProps {
    totalCount: number
    identifiers?: Identifier[]
    name: string
    identifiersToDownload: string[]
    setIdentifiersToDownload: any
}

export function RankDropdown(
    { totalCount, identifiers, name, identifiersToDownload, setIdentifiersToDownload }: RankDropdownProps) {
        
    const gemCount = 5
    const legendGemCount = 16
    const isLegend = name === t`certificate.level.legend`
    const [count, setCount] = useState(totalCount)
    const [gemIdentifiers, setGemIdentifiers] = useState<string[][]>(() => Array(isLegend ? legendGemCount : gemCount).fill([]))
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const [checkedItems, setCheckedItems] = useState(
        gemIdentifiers.map(() => true)
    );

    const allChecked = checkedItems.every(Boolean);
    const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  
    useEffect(() => {
    
        const gemIdentifiers: (string[])[] = [];
        const gemIds: { [index:number]: string[]} = {};
        
        identifiers?.forEach((item) => {
          if (!gemIds[item.gem]) {
            gemIds[item.gem] = [];
          }
          item.gem >= legendGemCount ? gemIds[legendGemCount].push(...item.identifiers) : gemIds[item.gem].push(...item.identifiers);
        });
        
        Object.values(gemIds).slice(0, legendGemCount).forEach((identifiers, index) => {
            gemIdentifiers[index] = identifiers;
        });
        
        while (gemIdentifiers.length < (isLegend ? legendGemCount : gemCount)) {
            gemIdentifiers.push([]);
        }
        setGemIdentifiers(gemIdentifiers)
        setIdentifiersToDownload(gemIdentifiers.flat())

    }, [identifiers, isLegend, name, setIdentifiersToDownload])

    const handleAllCheckboxChange = (isChecked: boolean) => {
        setCheckedItems(gemIdentifiers.map(() => isChecked))
        if (isChecked) {
            setCount(totalCount);
            setIdentifiersToDownload(gemIdentifiers.flat())
        } else {
            setCount(0);
            setIdentifiersToDownload([])
        }
    }

    const handleCheckboxChange = (isChecked: boolean, index: number) => {
        setCheckedItems([
            ...checkedItems.slice(0, index),
            isChecked,
            ...checkedItems.slice(index + 1)
        ])
        if (isChecked) {
            setCount(count + gemIdentifiers[index].length)
            setIdentifiersToDownload(identifiersToDownload.concat(gemIdentifiers[index].flat()))
        } else {
            setCount(count - gemIdentifiers[index].length)
            const newArray = identifiersToDownload.filter((value) => {
                return !gemIdentifiers[index].flat().includes(value)
            })
            setIdentifiersToDownload(newArray)
        }
    };

    const multiTitle = checkedItems.map((value, index) => value ? convertToRoman(index + 1) : '').filter(String).join(', ')
    
    return (
        <>
            <Popover
                isOpen={isDropdownOpen}
                isLazy
                onClose={() => setIsDropdownOpen(false)}
                onOpen={() => setIsDropdownOpen(true)}
                strategy={'fixed'}
                matchWidth
                gutter={0}>
                <PopoverTrigger>
                    <Button
                        marginY={'8px'}
                        borderRadius={'8px'}
                        border={isDropdownOpen ? '2px solid #00ABE8' : '1px solid #4DC4EF'}
                        background={'#FFF'}
                        height={'40px'}
                        width={'450px'}
                        flexShrink={0}
                        color={'#33475F'}
                        fontSize={'16px'}
                        _hover={{
                            border: isDropdownOpen ? '2px solid #00ABE8' : '1px solid #00ABE8'
                        }}
                        _active={{ bg: '#FFF' }}
                        isDisabled={totalCount === 0}
                        display={'flex'}
                        justifyContent={'space-between'}
                        padding={4}
                    >
                        <Box verticalAlign={'middle'}>
                            {isIndeterminate ? multiTitle.replace('XVI', '∞') : t`certificates.all`} ({count})
                        </Box>
                        <Box>
                            {isDropdownOpen ? <DropdownChevronUp w={'20px'} h={'20px'} /> : <DropdownChevronDown w={'20px'} h={'20px'} />}
                        </Box>

                    </Button>
                </PopoverTrigger>
                <PopoverBody zIndex={'100'} position={'relative'}>
                    <PopoverContent w="full" m="0" bg={'#FFF'} maxH={'305px'} boxShadow={'0px -1px 6px -1px rgba(0, 0, 0, 0.10)'} borderRadius={'8px'}>
                        <VStack p={4} spacing="3" alignItems={'stretch'} overflowY={'auto'}
                            css={{
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#5C6C7F',
                                    borderRadius: '4px'
                                },
                                '&::-webkit-scrollbar': {
                                    width: '3px',
                                    height: '3px'
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: 'transparent',
                                    borderRadius: '4px',
                                    borderLeft: '1px solid transparent',
                                    borderRight: '1px solid transparent'
                                }
                            }}>
                            <Flex justifyContent={'space-between'} mr={{ sm: '1px', xxl: '6px' }}>
                                <Box>
                                    <Checkbox
                                        isChecked={allChecked}
                                        isIndeterminate={isIndeterminate}
                                        onChange={(e) => handleAllCheckboxChange(e.target.checked)}
                                    >
                                        <Text fontSize={'16px'} lineHeight={'20px'}>{t`certificates.all`}</Text>
                                    </Checkbox>
                                </Box>
                                <Box fontSize={'14px'}>
                                    {totalCount}
                                </Box>
                            </Flex>
                            <Divider />

                            {gemIdentifiers
                                .map((gemIdentifier, index) => (
                                    <>
                                    <Flex justifyContent={'space-between'} key={index} mr={{ sm: '1px', xxl: '6px' }}>
                                        <Box h={'20px'}>
                                            <Checkbox
                                                key={index}
                                                onChange={(e) => handleCheckboxChange(e.target.checked, index)}
                                                isChecked={checkedItems[index]}
                                                isDisabled={gemIdentifier.length === 0}
                                            >
                                                {index + 1 >= legendGemCount ? <Text fontSize={'24px'} lineHeight={'18px'}>&infin;</Text>
                                                    : <Text fontSize={'14px'} lineHeight={'20px'}>{convertToRoman(index + 1)}</Text>}
                                            </Checkbox>
                                        </Box>
                                        <Box fontSize={'14px'}>
                                            {gemIdentifier.length}
                                        </Box>
                                    </Flex>
                                    <Divider mt={0}/>
                                    </>
                                ))}
                        </VStack>
                    </PopoverContent>
                </PopoverBody>
            </Popover>
        </>
    );
}