import { StudentCertificates, Group, Classroom } from "../../../api/queries/certificate-reporting/api-types"
import { getIdentifiers } from "../../../containers/certificate-results/__helpers__/result-helper"
import { getStudentIDsAtGroupLevel, getStudentIDsAtClassLevel, getStudentIDsAllClasses } from "../../../containers/filter-container/__helpers__/filter-helper"
import { RANK_TYPE } from "../../../utils/enums"
import { RankDetailProps } from "../grid-types"

export function getCertificateObject (certificates: StudentCertificates[]) {
    const bronzeDetail = getIdentifiers(certificates, RANK_TYPE.bronze)
    const silverDetail = getIdentifiers(certificates, RANK_TYPE.silver)
    const goldDetail = getIdentifiers(certificates, RANK_TYPE.gold)
    const platinumDetail = getIdentifiers(certificates, RANK_TYPE.platinum)
    const emeraldDetail = getIdentifiers(certificates, RANK_TYPE.emerald)
    const diamondDetail = getIdentifiers(certificates, RANK_TYPE.diamond)
    const epicDetail = getIdentifiers(certificates, RANK_TYPE.epic)
    const legendDetail = getIdentifiers(certificates, RANK_TYPE.legend)
    
    const totalIdentifiers = bronzeDetail.identifiers.concat(silverDetail.identifiers)
            .concat(goldDetail.identifiers).concat(platinumDetail.identifiers)
            .concat(emeraldDetail.identifiers).concat(diamondDetail.identifiers)
            .concat(epicDetail.identifiers).concat(legendDetail.identifiers)

    const totalCount = totalIdentifiers.length

    return ({
        [RANK_TYPE.bronze] : bronzeDetail,
        [RANK_TYPE.silver] : silverDetail,
        [RANK_TYPE.gold] : goldDetail,
        [RANK_TYPE.platinum] : platinumDetail,
        [RANK_TYPE.emerald] : emeraldDetail,
        [RANK_TYPE.diamond] : diamondDetail,
        [RANK_TYPE.epic] : epicDetail,
        [RANK_TYPE.legend]  : legendDetail,
        total : { count: totalCount, identifiers: totalIdentifiers }
    } as RankDetailProps)
}

export function getCertificatesCount (studentIDs: string[], studentCertificates: StudentCertificates[]) {
    let certificates: StudentCertificates[] = []
    studentIDs.forEach((studentID: string) => {
        studentCertificates.forEach((studentCertificate: StudentCertificates) => {
            if (studentID === studentCertificate.studentId) {
                certificates.push(studentCertificate)
            }
        })
    })
    return getCertificateObject(certificates)
    
}

export function getCertificatesCountForGroup (group: Group, studentCertificates: StudentCertificates[]) {
    return getCertificatesCount(getStudentIDsAtGroupLevel(group), studentCertificates)
}

export function getCertificatesCountForClass (classRoom: Classroom, studentCertificates: StudentCertificates[]) {
    return getCertificatesCount(getStudentIDsAtClassLevel(classRoom), studentCertificates)
}

export function getCertificatesCountForAllClasses (classRooms: Classroom[], studentCertificates: StudentCertificates[]) {
    return getCertificatesCount(getStudentIDsAllClasses(classRooms), studentCertificates)
}