import { Box, Flex } from "@chakra-ui/react";
import { Dropdown } from "../dropdown/dropdown";
import { DropdownValue, DropdownVariant } from "../dropdown/dropdown.types";
import { useAppSelector } from "../../store/store";
import { TimeFilterDetail } from "../../api/queries/certificate-reporting/api-types";
import { DATE_FILTER_TYPE } from "../../utils/enums";
import { getLabel } from "../../containers/filter-container/__helpers__/filter-helper";
interface DateFiltersProps {
    onDateTimeChange: (value: DropdownValue) => void
}
export function DateFilters({ onDateTimeChange } : DateFiltersProps) {
    const {
        timeFilter
    } = useAppSelector(
        rootState => rootState.filtersContainerSlice
    )
    
    const timeFilterData = timeFilter.data as TimeFilterDetail[]
    const selectedTimeFilter = timeFilter.selected as TimeFilterDetail

    function getDateTimeFilters() {
        return timeFilterData?.map(({
            filterType,
            label
        }) => ({
            value: filterType,
            label: getLabel(filterType, label)
        })) || [];
    }

    return (
        <Flex
            height={'auto'}
            w={'auto'}
        >
            <Flex w={'100%'} alignItems={'center'} position={'relative'}>
                <Box width={'15px'} />
                <Dropdown
                        variant={DropdownVariant.fullStroke}
                        options={getDateTimeFilters()}
                        value={selectedTimeFilter?.filterType || DATE_FILTER_TYPE.LastWeek}
                        onOptionClick={onDateTimeChange}
                        label={selectedTimeFilter?.label}
                        dividerIndex={4}
                />
            </Flex>
        </Flex>
    )
}