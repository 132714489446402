import { Flex, Heading } from "@chakra-ui/react"
import { InfoPanel } from "../info-panel/info-panel"
import { t } from "@lingui/macro";

export function Header() {
    return (
        <Flex w={'calc(100vw - 80px)'}  minW={{sm:'900px', xxl:'960px'}} justifyContent={'space-between'}>
            <Heading fontWeight={'400'}>{t`certificates`}</Heading>
            <InfoPanel />      
        </Flex>
    )
}