
export type FetcherOptions<TVariables> = {
    method: string
    url: string
    query: string
    variables?: TVariables,
    body?: object,
    headers?: RequestInit['headers']
};

export async function fetcher<TData, TVariables> ({
    method,
    query,
    variables,
    url,
    headers,
    body
}: FetcherOptions<TVariables>): Promise<TData|null>{
    const res = await fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            ...(headers ?? {})
        },
        ...(body && {body: JSON.stringify(body)})
    });

    if (res.status !== 200 && res.status !== 201 && res.status !== 204) {
        res.text().then(text => { console.error(text) })
        throw new Error(
                res.statusText || 'Unknown fetcher status message',
                { cause: res }
            );
    }
    try {
        if (method === "POST" && (url.indexOf('submitRequest') > -1 || url.indexOf('certificates/download') > -1)) {
            const text = await res.text()
            const str = text.replace(/^"(.*)"$/, '$1');
            const jsonString = `{"data": "${str}"}`
            return JSON.parse(jsonString);
        }
        if (method === "GET" && (url.indexOf('report/download') > -1 || url.indexOf('certificates/download') > -1)) {
            const blob = await res.blob()
            return blob as any;
        }
        const json = await res.json();
        return json;
    } catch(e) {
        console.warn('Fetcher - unable to transform response', e);
        return null;
    }
}
