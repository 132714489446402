import { Center, GridItem, SimpleGrid, Switch, useDisclosure, Text, Flex, useBoolean, Skeleton } from "@chakra-ui/react";
import { Classroom, Group, PastCertificatesSummary, StudentCertificates, TimeFilterDetail } from "../../api/queries/certificate-reporting/api-types";
import { useEffect } from "react";
import { DropdownValue } from "../dropdown/dropdown.types";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { setDateTimeFilter, setPastYearFilters, setSelectedClassroom, setSelectedGroup, setTimeModal } from "../../containers/filter-container/filter-container.slice";
import { useCertificateSummaryReport } from "../../hooks/use-summary-report";
import { disableSkeleton, enableSkeleton, setNewCertificates, setPastCertificates } from "../../containers/certificate-results/certificate-results.slice";
import { ModifyDates } from "./modify-dates";
import { DATE_FILTER_TYPE } from "../../utils/enums";
import { ClassFilter } from "./class-filter";
import { GroupFilter } from "./group-filter";
import { DateFilters } from "./date-filter";
import { usePastCertificateSummaryQuery } from "../../api/queries/certificate-reporting/past-certificates/use-past-certificate-summary";
import { clearCheckedItems, clearIdentifiersToDownload } from "../../containers/download/download-container.slice";
import { dropdownContainerFontSize, dropdownContainerWidth, gridGap, toggleSwitchWidth } from "../dropdown/dropdown.styled";
import { useGetPastYearFilters } from "../../api/queries/certificate-reporting/use-get-past-years";
import { t } from "@lingui/macro"

export function Filters() {
    const [isPastYearToggle, setIsPastYearToggle] = useBoolean(false)
    const dispatch = useAppDispatch()
    const {
        studentIDs,
        timeFilter,
        isTimeModalActive,
        classroom,
        group
    } = useAppSelector(
        rootState => rootState.filtersContainerSlice
    )
    const selectedTimeFilter = timeFilter.selected as TimeFilterDetail
    const selectedClassroom = classroom.selected as Classroom
    const selectedGroup = group.selected as Group

    const { onOpen } = useDisclosure()
    const { mutation, mutate } = useCertificateSummaryReport(studentIDs, selectedTimeFilter?.startDate, selectedTimeFilter?.endDate);

    const { isLoading, data } = usePastCertificateSummaryQuery(
        selectedTimeFilter.startDate,
        selectedTimeFilter.endDate,
        selectedClassroom.id,
        selectedGroup.id,
        (selectedTimeFilter.startDate !== ''
            && selectedTimeFilter.endDate !== ''))


    const pastCertificateSummary = !isLoading && data as PastCertificatesSummary

    const pastYearsData = useGetPastYearFilters(isPastYearToggle)

    useEffect(() => {
        if (pastYearsData.data && !pastYearsData.isLoading) {
            const pastYears = pastYearsData.data as TimeFilterDetail[]
            dispatch(setPastYearFilters({ dateTimeFilters: pastYears, isPastYears: isPastYearToggle}))
        }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isPastYearToggle, pastYearsData.data])

    useEffect(() => {
        if (pastCertificateSummary) {
            dispatch(setPastCertificates({ pastCertificateSummary }))
        }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pastCertificateSummary])

    useEffect(() => {
        mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    useEffect(() => {
      function handleMutation() {
            if (mutation.status === 'success' && mutation.data) {
                mutation.reset();
                const studentCertificates = mutation.data as StudentCertificates[]
                dispatch(setNewCertificates({studentCertificates}))
                dispatch(clearCheckedItems())
                dispatch(clearIdentifiersToDownload())
            } else if (mutation.status === 'error') {
                mutation.reset();
                dispatch(clearCheckedItems())
                dispatch(clearIdentifiersToDownload())
            }
            if (mutation.status === 'loading') {
                dispatch(enableSkeleton())
            } else {
                dispatch(disableSkeleton())
            }
        }
      handleMutation();
      // eslint-disable-next-line
    }, [mutation]);

    function onClassChange(value: DropdownValue) {
        dispatch(setSelectedClassroom(Number(value)))
        mutate()
    }

    function onGroupChange(value: DropdownValue) {
        dispatch(setSelectedGroup(Number(value)))
        mutate()
    }

    function onDateTimeChange(value: DropdownValue) {
        dispatch(setDateTimeFilter(value.toString()))
        if (value !== DATE_FILTER_TYPE.DateRange) {
            dispatch(setTimeModal(false))
            mutate()
        } else {
            dispatch(setTimeModal(true))
            onOpen()
        }
    }
    
    return (
        <SimpleGrid
            templateColumns={'1fr 1fr 1fr 180px'}
            w={'calc(100vw - 80px)'}
            minW={{sm:'900px', xxl:'960px'}}
            backgroundColor={'white'}
            borderRadius={'20px'}
            columnGap={gridGap}
            p={'28px 32px'}
            minH={'100px'}
            boxShadow={'0px 1px 3px 0px rgba(0, 25, 55, 0.12)'}
        >
            <GridItem w='100%'>
                <ClassFilter onClassChange={onClassChange} />
            </GridItem>
            <GridItem w='100%'>
                <GroupFilter onGroupChange={onGroupChange} />
            </GridItem>
            <GridItem w='100%'>
                { isPastYearToggle && pastYearsData.isLoading ? 
                    <Skeleton w={dropdownContainerWidth} h={'48px'} /> 
                    : <DateFilters onDateTimeChange={onDateTimeChange} />}
            </GridItem>
            <GridItem w='100%'>
                <Flex flexDirection={'column'} gap={'5px'} alignItems={'flex-end'} width={toggleSwitchWidth}>
                    <Switch isChecked={isPastYearToggle} onChange={setIsPastYearToggle.toggle}/>
                    <Text textAlign={'end'} fontSize={dropdownContainerFontSize}>
                        {isPastYearToggle ? t`filter.showPastYears.on` : t`filter.showPastYears.off`}
                    </Text>
                </Flex>
            </GridItem>
            { isTimeModalActive &&
            <GridItem colSpan={4} display={'flex'} justifyContent={'center'} w='100%'>
                <Center 
                    width={'620px'}
                    padding={'15px'}
                    borderRadius={'12px'}
                    boxShadow={'0px 1px 2px 0px rgba(0, 25, 55, 0.06), 0px 1px 3px 0px rgba(0, 25, 55, 0.16)'}
                >
                    <ModifyDates />
                </Center>
            </GridItem>
            }
            
        </SimpleGrid>
    )
}