const portalSidebarWidth = 70;

export const tabletLandscape = 1280;

export const tabletPortrait = 1024;

export const breakpoints = {
    tablet: `${tabletLandscape - portalSidebarWidth}px`,
    portrait: `${tabletPortrait - portalSidebarWidth}px`,
    base: 0,
    small: `${667 - portalSidebarWidth}px`,
    medium: `${992 - portalSidebarWidth}px`,
    large: `${1280 - portalSidebarWidth}px`,
    sm: `${480 - portalSidebarWidth}px`,
    md: `${768 - portalSidebarWidth}px`,
    lg: `${992 - portalSidebarWidth}px`,
    xlg: `${1025 - portalSidebarWidth}px`,
    xxl: `${1280 - portalSidebarWidth}px`,
    xxxl: `${1536 - portalSidebarWidth}px`
};
