import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit';

interface DownloadRequestState {
    identifiersToDownload: string[],
    checkedAllItems: boolean[]
}
interface CheckedActionType {
    totalIdentifiers: string[]
}

const initialState = {
    identifiersToDownload: [],
    checkedAllItems: [false]
}

const downloadContainerSlice = createSlice({
    name: 'downloadContainerSlice',
    initialState,
    reducers: {
        addIdentifiersToDownload(state: DownloadRequestState, action: PayloadAction<CheckedActionType>) {
            const existingIdentifiers = state.identifiersToDownload
            const newIdentifiers = action.payload.totalIdentifiers
            newIdentifiers.forEach((value) => {
                if (existingIdentifiers.indexOf(value) === -1) {
                    existingIdentifiers.push(value)
                }
            });
            state.identifiersToDownload = existingIdentifiers
        },
        removeIdentifiersToDownload(state: DownloadRequestState, action: PayloadAction<CheckedActionType>) {
            const newArray = state.identifiersToDownload.filter((value) => {
                return !action.payload.totalIdentifiers.includes(value)
            })
            state.identifiersToDownload = newArray
        },
        clearIdentifiersToDownload(state: DownloadRequestState) {
            state.identifiersToDownload = []
        },
        setCheckedAllItems(state: DownloadRequestState, action: PayloadAction<boolean[]>) {
            state.checkedAllItems = action.payload
        },
        clearCheckedItems(state: DownloadRequestState) {
            const existingItems = state.checkedAllItems
            state.checkedAllItems = existingItems.map(() => false)
        }
    }
});

export const {
    addIdentifiersToDownload,
    removeIdentifiersToDownload,
    clearIdentifiersToDownload,
    setCheckedAllItems,
    clearCheckedItems
} = downloadContainerSlice.actions;

export default downloadContainerSlice;