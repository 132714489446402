import { createStandaloneToast } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { DropdownOption, DropdownValue, RightIconProps } from "../dropdown/dropdown.types";
import { BatchModalProps } from "./download-types";
import { useEffect, useState } from "react";
import { useEnvConfig } from "../../env-config";
import { usePastDownload } from "../../hooks/use-past-download";
import { usePdfName } from "./use-pdf-name";
import { usePastGetDownloadReport } from "../../api/queries/certificate-reporting/past-certificates/use-get-download";
import { displayDownloadErrorToast } from "../toasts/toasts";
import { CommonBatchModal } from "./common-batch-modal";
import { CheckIcon } from "@chakra-ui/icons";

export function PastBatchModal({name='', count, isOpen, onClose, type}: BatchModalProps) {

    const [ isPastDownloading, setIsPastDownloading ] = useState(false)
    const [ selectedBatchIndex, setSelectedBatchIndex ] = useState(1)
    const [requestIdentifier, setRequestIdentifier] = useState('')
    const [ batches, setBatches ] = useState<Array<number>>([])
    const { DOWNLOAD_LIMIT } = useEnvConfig();
    const batchText = t`certificate.batch`
    const pdfName = usePdfName(name, batches[selectedBatchIndex-1], true, selectedBatchIndex)
    const downLoadQuery = usePastGetDownloadReport(requestIdentifier !== '' && requestIdentifier !== 'done', requestIdentifier, pdfName)
    const { toast } = createStandaloneToast();
    const [downloadedIndexes, setDownloadedIndexes] = useState<Array<number>>([])
    
    const { mutation, mutate } = usePastDownload(
        type || 0,
        batches[selectedBatchIndex-1],
        selectedBatchIndex
        );

    useEffect(() => {
        if (count > DOWNLOAD_LIMIT) {
            setBatches(splitBatches(count))
        }
    // eslint-disable-next-line
    }, [setBatches, isOpen, DOWNLOAD_LIMIT])


    function splitBatches(count: number) {
        const chunks = [];
        while (count > 0) {
            chunks.push(Math.min(count, DOWNLOAD_LIMIT));
            count -= DOWNLOAD_LIMIT;
        }
        return chunks;
    }

    function getBatchOptions() {
        return batches?.map((batch, index) => {
            const batchValue = index + 1
            const option: DropdownOption = {
                value: batchValue,
                label: `${batchText} ${batchValue} (${batch})`
            }
            if (downloadedIndexes.includes(batchValue)) {
                option.rightIcon = ({
                    iconProps
                }: RightIconProps) => (
                    <CheckIcon {...iconProps}/>
                )
            }
            return option
        }) || []
    }
    
    function onBatchChange(value: DropdownValue) {
        const selectedBatchIndex = value as number
        setSelectedBatchIndex(selectedBatchIndex)
        setRequestIdentifier('')
    }

    const onDownloadClick = () => {
        if (count > 0) {
            mutate()
        }
    }
    
    if (downLoadQuery.isSuccess) {
        setRequestIdentifier('done')
        const indexes = downloadedIndexes.concat(selectedBatchIndex)
        setDownloadedIndexes(indexes)
    }
    
    if (downLoadQuery.isError) {
        toast.close('genericErrorToast')
        displayDownloadErrorToast()
        setRequestIdentifier('')
    }
    useEffect(() => {
        function handleMutation() {
            if (mutation.status === 'success' && mutation.data) {
                const requestData = mutation.data as { data: string }
                if (requestData) {
                    setRequestIdentifier(requestData?.data)
                }
                mutation.reset();
            } else if (mutation.status === 'error') {
                displayDownloadErrorToast()
                setRequestIdentifier('')
                mutation.reset();
            }
        }
    handleMutation();
      // eslint-disable-next-line
    }, [mutation]);


    useEffect( () => {
        if (setIsPastDownloading) {
            if (downLoadQuery.isFetching || mutation.status === 'loading') {
                setIsPastDownloading(true)
            } else {
                setIsPastDownloading(false)
            }
        }
    }, [downLoadQuery, setIsPastDownloading, mutation]); 
    
    return (
        <CommonBatchModal
            isOpen={isOpen}
            onClose={onClose}
            heading={`${count} ${name} ${count > 1 ? t`certificates` : t`certificate`}`}
            batchOptions={getBatchOptions()}
            selectedBatchIndex={selectedBatchIndex}
            onBatchChange={onBatchChange}
            onDownloadClick={onDownloadClick}
            isDownloading={isPastDownloading}
            isDownloadComplete={requestIdentifier === 'done'}
        />
    )
}