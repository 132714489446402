import { useMutation } from '@tanstack/react-query';
import { useAuthToken, useFetcher } from "../use-fetcher";
import { useEnvConfig } from '../../../../env-config';

export function useCertificateSummaryQuery (StudentIds: string[], StartDate: string, EndDate: string) {
    
    const { CERTIFICATE_REPORT_GATEWAY } = useEnvConfig();
    const postCertificateSummary = useFetcher(
        CERTIFICATE_REPORT_GATEWAY,
        "report/certificatesSummary",
        "POST",
        {headers: {AuthToken : useAuthToken()}},
        {
            StudentIds,
            StartDate,
            EndDate
        });

    return useMutation({
        mutationFn: postCertificateSummary,
        mutationKey: ['CertificateSummary']
    })
}
