import { t } from "@lingui/macro";
import { useAppSelector } from "../../store/store";
import { DATE_FILTER_TYPE } from "../../utils/enums";

export function usePdfName(name: string, count: number, isOld: boolean, batchIndex?: number) {
    const {
        timeFilter
    } = useAppSelector(
        rootState => rootState.filtersContainerSlice
    )
    const selectedFilterType = timeFilter.selected.filterType as DATE_FILTER_TYPE
    let yearName = timeFilter.selected.label
    const certificateText = isOld ? t`certificates.past` : t`certificates`
    const batchText = t`certificate.batch`
    if (selectedFilterType === DATE_FILTER_TYPE.LastWeek ||
        selectedFilterType === DATE_FILTER_TYPE.ThisWeek ||
        selectedFilterType === DATE_FILTER_TYPE.DateRange) {
        yearName = `${timeFilter.selected.startDate} - ${timeFilter.selected.endDate}`
    }
    const pdfName = `${yearName}_${count}${name ? `_${name}` : ''}_${certificateText}${batchIndex ? `_${batchText}_${batchIndex}` : ''}`
    return `${pdfName}.pdf`
 }