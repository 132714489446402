import { Flex } from "@chakra-ui/react";
import { CertificateTabs } from "./certificate-tabs";
import { useAppSelector } from "../../store/store";

import { TABS } from "../../utils/enums";
import { PastCertificateTiles } from "./past-certificate-tiles";
import { NewCertificateTiles } from "./new-certificate-tiles";
import { EmptyCertificatesState } from "./empty-certificates-state";

export function RankTiles() {
    const {
        activeTab,
        newCertificates,
        pastCertificates
    } = useAppSelector(
        rootState => rootState.certificateResultsSlice
    )
    
    const showNewCertificates = newCertificates.total.count > 0
    const showPastCertificates = pastCertificates.total.count > 0
    const hasNoCertificates = newCertificates.total.count === 0 && pastCertificates.total.count === 0
    return (
        <Flex
            direction={'column'}
            backgroundColor={'white'}
            borderRadius={'20px'}
            justifyContent={'center'}
            alignItems={'center'}
            boxShadow={'0px 1px 3px 0px rgba(0, 25, 55, 0.12)'}
            w={'calc(100vw - 80px)'}
            minW={{sm:'900px', xxl:'960px'}}
        >
            <CertificateTabs />
            { hasNoCertificates && <EmptyCertificatesState />}
            { activeTab === TABS.newCertificates && showNewCertificates &&
                <NewCertificateTiles />}
            { activeTab === TABS.pastCertificates && showPastCertificates &&
                <PastCertificateTiles />
            }
        </Flex>
        
    )
}