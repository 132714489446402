import { fetcher } from './fetcher';

export const useQueryParams = () => {
    return new URLSearchParams(window.location.search);
}

export const useAuthToken = () => {
    return useQueryParams().get('token') || '';
}

type Options = {
    headers?: RequestInit['headers']
};

/**
 * Wrapper over fetcher that will automatically inject env url
 * from the env context in the app.
 *
 * 
 */
export const useFetcher = <TData, TVariables> (
    gateway: string,
    query: string,
    method: string,
    options: Options = {},
    body?: {}
): ((variables?: TVariables) => Promise<TData | null>) => {
    const { headers } = options;
 
    return async (variables) => {
        try {
            // noinspection UnnecessaryLocalVariableJS // leave this for try/catch
            const response = await fetcher<TData, TVariables>({
                method: method,
                url: `${gateway}${query}`,
                variables,
                headers,
                query,
                body
            });

            return response;
        } catch (error: any) {
            console.error('fetcher error', `${error} `);
            throw new Error(error);
        }
    };
};
