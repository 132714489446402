import { Checkbox, Flex, Text, useMediaQuery, Box } from "@chakra-ui/react";
import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/table";

import Bronze from "../../assets/bronze.svg"
import Silver from "../../assets/silver.svg"
import Gold from "../../assets/gold.svg"
import Platinum from "../../assets/platinum.svg"
import Emerald from "../../assets/emerald.svg"
import Diamond from "../../assets/diamond.svg"
import Epic from "../../assets/epic.svg"
import Legend from "../../assets/legend.svg"
import { useAppDispatch, useAppSelector } from "../../store/store";
import { TABS } from "../../utils/enums";
import { Classroom, Identifier, StudentCertificates, TimeFilterDetail } from "../../api/queries/certificate-reporting/api-types";
import { DATE_DISPLAY_FORMAT_DD_MON_YYYY, DATE_FORMAT_DD_MM_YYYY } from "../../utils/consts";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { ClassRow } from "./class-row";
import { addIdentifiersToDownload, clearIdentifiersToDownload, setCheckedAllItems } from "../../containers/download/download-container.slice";
import { TableHeader } from "./grid-header";
import { getCertificatesCountForAllClasses } from "./__helpers__/result-grid-helper";
import { t } from "@lingui/macro";
import { GridBodySkeleton } from "./result-grid-skeleton/grid-body-skeleton";
import { GridNavigator } from "./navigator/navigator";

export function CertificateGrid() {
    const [isLargerThan1090] = useMediaQuery('(min-width: 1090px)', {
        ssr: true,
        fallback: false,
    })
    const [sliderProps, setSliderProps] = useState({})
    const dispatch = useAppDispatch()
    const {
        activeTab,
        studentCertificates,
        isSummaryLoading
    } = useAppSelector(
        rootState => rootState.certificateResultsSlice
    )

    const certificates = studentCertificates as StudentCertificates[]
    const {
        classroom,
        timeFilter
    } = useAppSelector(
        rootState => rootState.filtersContainerSlice
    )

    const {
        checkedAllItems
    } = useAppSelector(
        rootState => rootState.downloadContainerSlice
    )

    const selectedClass = classroom.selected as Classroom
    const selectedTimeFilter = timeFilter.selected as TimeFilterDetail
    const startDateDisplay = moment(selectedTimeFilter.startDate, DATE_FORMAT_DD_MM_YYYY).format(DATE_DISPLAY_FORMAT_DD_MON_YYYY)
    const endDateDisplay = moment(selectedTimeFilter.endDate, DATE_FORMAT_DD_MM_YYYY).format(DATE_DISPLAY_FORMAT_DD_MON_YYYY)
    const dateHeading = `| ${startDateDisplay} to ${endDateDisplay}`

    const allChecked = checkedAllItems.every(Boolean);
    const isIndeterminate = checkedAllItems.some(Boolean) && !allChecked;

    const allClasses = useMemo(() => {
        if (selectedClass.id === 0) {
            const classes = classroom.data as Classroom[]
            return classes.slice(1)
        } else {
            return [selectedClass]
        }
    }, [classroom.data, selectedClass]);

    useEffect(() => {
        dispatch(setCheckedAllItems(allClasses.map(() => false)))
    },[allClasses, dispatch])

    if (activeTab !== TABS.newCertificates) {
        return (<></>)
    }

    function onAllCheckboxChange( e:any ) {
        dispatch(setCheckedAllItems(allClasses.map(() => e.target.checked)))
        if (e?.target?.checked) {
            const identifiers  = getCertificatesCountForAllClasses(allClasses, studentCertificates).total.identifiers as Identifier[]
            const totalIdentifiers = identifiers.flatMap(item => item.identifiers)
            dispatch(addIdentifiersToDownload({ totalIdentifiers }))
        } else {
            dispatch(clearIdentifiersToDownload())
        }
    }
    
    const scrollRight = () => {
        setSliderProps({
            transform : 'translateX(-100px)'
        }) 
    }
    
    const scrollLeft = () => {
        setSliderProps({
            transform :'translateX(0)'
        })
    }
    
    return (
        <>
            { certificates.length > 0 &&
                <Box {...sliderProps} h={'100%'}>
                    <TableContainer margin={{sm:'20px', xxl: '30px 70px'}} overflowX="unset" overflowY="unset" >
                    <Table variant='unstyled' style={{borderCollapse:"separate", borderSpacing:"0"}} width={'auto'} align={'center'}>
                    <Thead position="sticky" top={0} zIndex="docked" bgColor={'#FFF'}>
                        <Tr>
                            <Th width={{sm: '300px', xxl:'400px'}} maxWidth={'400px'} maxHeight={'72px'} textTransform={'none'}>
                                <Flex
                                    lineHeight={'20px'}
                                    fontSize={'15px'}
                                    flexDirection={'row'}
                                    justifyContent={'center'}
                                    flexFlow={'row wrap'}
                                >
                                    <Text fontWeight={500} pr={'5px'}
                                        maxH='42px'
                                        lineHeight= '21px'
                                        whiteSpace='normal'
                                        overflow={'hidden'}>
                                        {selectedClass.name}
                                    </Text>
                                    <Text fontWeight={400}>
                                        {dateHeading}
                                    </Text>
                                </Flex>
                            </Th>
                            <Th 
                                borderBottom={'1px solid #DBE0E6'}
                                borderRight={'1px solid #DBE0E6'}
                                bgColor={'#F8FBFC'}
                                box-shadow='0px -1px 0px 0px #E2E8F0 inset'
                                width={'74px'}
                                height={'72px'}
                                maxWidth={'74px'} maxHeight={'72px'}
                            >
                                <Checkbox
                                    size='lg'
                                    isChecked={allChecked}
                                    isIndeterminate={isIndeterminate}
                                    onChange={(e) => onAllCheckboxChange(e)}
                                    background={'white'}
                                    border={'1px solid #CBD3DC'}
                                />
                            </Th>
                            <TableHeader name={t`certificate.level.bronze`} image={Bronze}/>
                            <TableHeader name={t`certificate.level.silver`} image={Silver}/>
                            <TableHeader name={t`certificate.level.gold`} image={Gold}/>
                            <TableHeader name={t`certificate.level.platinum`} image={Platinum}/>
                            <TableHeader name={t`certificate.level.emerald`} image={Emerald}/>
                            <TableHeader name={t`certificate.level.diamond` } image={Diamond}/>
                            <TableHeader name={t`certificate.level.epic`} image={Epic}/>
                            <TableHeader name={t`certificate.level.legend`} image={Legend}/>
                            
                            <Th
                                borderBottom={'1px solid #DBE0E6'}
                                borderRight={'1px solid #DBE0E6'}
                                h={'72px'}
                                w={'74px'}
                                p={'10px 12px'}
                                textAlign={'center'}
                            >
                                {t`total`}
                            </Th>
                            <Th>
                            {! isLargerThan1090 && <GridNavigator scrollRight={scrollRight} scrollLeft={scrollLeft}/> }

                            </Th>
                        </Tr>
                        
                    </Thead>

                    <Tbody>
                        { isSummaryLoading 
                        ? <GridBodySkeleton /> 
                        :
                        allClasses.map(
                            (classroom, index) => {
                                return <ClassRow
                                            key={classroom.id}
                                            classroom={classroom}
                                            classIndex={index}
                                        />
                            })
                        }
                    </Tbody>
                    </Table>
                    </TableContainer>
                </Box>
            }
        </>
        
        
        
    )
}