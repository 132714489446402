function convertToRoman(num: number) {
    let numberArr = [10, 9, 5, 4, 1];
    let RomanArr = [
      "X",
      "IX",
      "V",
      "IV",
      "I"
    ];
    let result = [];

  
    const findElement = (e: number) => {
      return e <= num;
    };
  
    while (num > 0) {
      let nextHighest = numberArr.find(findElement);
  
      if (nextHighest) {
        result.push(RomanArr[numberArr.indexOf(nextHighest)]);
        num -= nextHighest;
      }
      
    }
    let newResult = result.join("");
  
    return newResult;
  }
  
  export default convertToRoman;