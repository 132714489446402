import { Button, CircularProgress } from "@chakra-ui/react"
import { CheckIcon, DownloadIcon } from '@chakra-ui/icons'
import { ModalPrintButtonProps } from "./download-types"

export function ModalPrintButton({onClick, name, isDownloading, isDownloadComplete}: ModalPrintButtonProps) {
    return (
        <Button
            colorScheme='blue'
            mr={3}
            onClick={onClick}
            borderRadius={'40px'}
            padding={'0 30px'}
            opacity={isDownloading ? '70%' : 'inherit'}
            >
            { isDownloading ?
                <CircularProgress isIndeterminate size={'15px'} marginRight={'10px'}/>
                :
                    isDownloadComplete ? <CheckIcon width={'16px'} height={'16px'} marginRight={'10px'}/> 
                    :
                    <DownloadIcon width={'16px'} height={'16px'} marginRight={'10px'} />
            }
            {name}
        </Button>
    )
}

export function PopoverPrintButton({onClick, name}: ModalPrintButtonProps) {
    return (
        <Button
            variant={'unstyled'}
            width={'100%'}
            textAlign={'left'}
            fontWeight={'400'}
            fontSize={'18px'}
            background={'white'}
            border={0}
            borderRadius={'20px'}
            onClick={onClick}
            padding={'10px 20px'}
            _hover={{color: '#0063A7'}}
        >
            { name }
        </Button>
    )
}