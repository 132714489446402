import React from 'react'
import {
  Box,
  BoxProps,
  Button,
  useBoolean,
  useMediaQuery
} from '@chakra-ui/react'
import { NetworkErrorPage } from "@3plearning/chakra-student-components";
import { breakpoints } from '../../chakra-ui/theme/breakpoints'
import RotateDeviceImage from './assets/rotate-device-image.svg'
import { t } from "@lingui/macro";

export function MinViewportSizeWarning() {
  const [viewportSizeSupported] = useMediaQuery([
    `(min-width: ${breakpoints.medium})`
  ], {
    ssr: false
  })

  if (viewportSizeSupported) {
    return null
  }

  return (
    <WarningScreen
      warningMessage={t`viewport.sizeWarning`}
      imageSrc={RotateDeviceImage}
    />
  )
}

type Props = {
  warningMessage: string
  imageSrc: string
}

function WarningScreen({ warningMessage, imageSrc }: Props) {
  const [isOpen, setIsOpen] = useBoolean(true)
  return (
    <>
        <NetworkErrorPage
            size={'base'}
            headingText={t`viewport.heading`}
            messageText={warningMessage}
            buttons={[
              () => (
                <Button w='220px' onClick={setIsOpen.off}>{'Ok'}</Button>
              )
            ]}
            imageSrc={imageSrc}
            layout='vertical'
            isOpen={isOpen}
            modalContentStyles={{
              top: '150px',
              borderRadius: '20px'
            }}
            modalBodyStyles={{
              padding: '20px 0 !important',
              overflow: 'auto'
            }}
        >
          <Circle
            opacity={0.4}
            bg={'#FEE5DA'}
            size={'203px'}
            top={'-74px'}
            left={'-143px'}
            zIndex={-1}
          />
          <Circle
            bg={'#FEF3D6'}
            size={'45px'}
            top={'214px'}
            right={'59px'}
            zIndex={-1}
          />
          </NetworkErrorPage>
            
    </>
  )
}
function Circle({ size, ...restProps }: BoxProps & { size: string }) {
  return (
    <Box
      borderRadius={'100%'}
      position={'absolute'}
      {...restProps}
      w={size}
      h={size}
    />
  )
}