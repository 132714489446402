import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react"
import { checkboxAnatomy } from '@chakra-ui/anatomy'


const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const sizes = {
  xl: definePartsStyle({
    container: defineStyle({
      boxSize: '20px',
      iconSize: '20px',
      w: '20px',
      h: '20px',
      maxW: '20px',
      minW: '20px',
    })
  })
}

export const checkboxTheme = defineMultiStyleConfig({ sizes })
