import { Box, Grid, GridItem } from "@chakra-ui/react";
import { Header } from "../header/header";
import { Filters } from "../filters/filters";
import { RankTiles } from "../rank-tiles/rank-tiles";
import { Footer } from "../footer/footer";
import { CertificateGrid } from "../result-grid/certificate-grid";
import { useInitialData } from "../../api/queries/certificate-reporting/use-get-init-data";
import { useAppDispatch } from "../../store/store";
import { setClassGroupFiltersInitialData, setDateTimeFiltersInitData } from "../../containers/filter-container/filter-container.slice";
import { Classroom, TimeFilterDetail } from "../../api/queries/certificate-reporting/api-types";
import OfflineErrorModal from '../error-modals/offline-error-modal';
import { useOnlineStatus } from '../../hooks/use-online-status';
import { LocalesProvider } from "../../locales/LocalesProvider";
import { CertificateReportLoader } from "../skeleton-loader/skeleton-loader";
import { MinViewportSizeWarning } from "../min-viewport-size-warning/min-viewport-size-warning";
import { isDesktop } from "react-device-detect";

export function CertificateReport() {
    
    const [ classRoomFilters, dateTimeFilters ] = useInitialData()
    const dispatch = useAppDispatch()
    const { isOnline } = useOnlineStatus();

    if (classRoomFilters.data && !classRoomFilters.isLoading) {
        const classRooms = classRoomFilters.data as Classroom[]
        dispatch(setClassGroupFiltersInitialData({classRooms}))
    }
    if (!dateTimeFilters.isLoading && dateTimeFilters.data) {
        const dateTimeFiltersData = dateTimeFilters.data as TimeFilterDetail[] || []
        dispatch(setDateTimeFiltersInitData({dateTimeFilters: dateTimeFiltersData}))
    }

    const isInitDataReady = classRoomFilters.data && !classRoomFilters.isLoading && !dateTimeFilters.isLoading && dateTimeFilters.data
    return(
        <>
        <LocalesProvider>
        {
            <OfflineErrorModal isOpen={!isOnline} />
        }
        <MinViewportSizeWarning />
        {
            isInitDataReady ? 
            <Grid gap={1} h={'calc(100vh - 70px)'} overflowY={'auto'} overflowX={'hidden'} maxW={'100%'} >
                <GridItem>
                    <Grid
                        gap={2}
                        background={'linear-gradient(123deg, #E2FEF2 0%, #ADEDF3 67.18%, #BEEBF7 100%)'}
                        p={{sm: '10px 20px', xxl:'20px 40px'}}>
                        <Header />
                        <Filters />
                        <RankTiles />
                    </Grid> 
                </GridItem>
                <GridItem h={isDesktop ? '100%': 'calc(100% + 70px)'}>
                    <CertificateGrid />
                    <Box position={'fixed'} bottom={isDesktop ? '0': '70px'} w={'100%'} zIndex={'1003'}>
                        <Footer />
                    </Box>
                </GridItem>
            </Grid> : <CertificateReportLoader />
        }
        </LocalesProvider>
        </>
    )
}