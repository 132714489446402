import { Box } from "@chakra-ui/react";
import { DotFlashingWrapper } from './dot-flashing.styled';

const DotFlashing = () => {
    return (<>
        <DotFlashingWrapper>
            <Box ml='30px' className='dot-flashing' />
        </DotFlashingWrapper>
    </>)
};

export default DotFlashing;
