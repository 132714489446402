import React from 'react';

import { ArrowForwardIcon } from '@chakra-ui/icons'
import * as styled from './date-arrow-icon.styled';

const DateArrowIcon = () => {
    return (
        <styled.container>
            <ArrowForwardIcon w={'2em'} />
        </styled.container>
    );
};

export default DateArrowIcon;
