export const black = '#000';

export const white = {
    0: '#FFF',
    50: 'rgba(255, 255, 255, 0.5)',
    60: 'rgba(255, 255, 255, 0.6)'
};

export const primary = {
    25: '#ECF9FE',
    50: '#DCF4FD',
    100: '#D0F1FD',
    200: '#BCEAFD',
    300: '#99DDF6',
    400: '#4DC4EF',
    500: '#00ABE8',
    600: '#007ACC',
    700: '#0063A7',
    800: '#004786',
    900: '#00336F'
};

export const secondary = {
    50: '#FFFAEE',
    100: '#FEF3D6',
    200: '#FEE8AD',
    300: '#FBE294',
    400: '#F7D064',
    500: '#F5BC23',
    600: '#E4AB10',
    700: '#A56A00',
    800: '#854C00',
    900: '#653A00'
};

export const neutral = {
    25: '#F8FBFC',
    50: '#F2F5F7',
    100: '#EDF1F4',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A304B',
    900: '#001937'
};

export const success = {
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#9BE49D',
    400: '#58D25B',
    500: '#29B539',
    600: '#1D9B37',
    700: '#148235',
    800: '#0D6830',
    900: '#024D1F'
};

export const error = {
    50: '#FEF6F7',
    100: '#FDEEEF',
    200: '#FBDFE1',
    300: '#F6BFC3',
    400: '#F0939B',
    500: '#EB6973',
    600: '#E22837',
    700: '#C51B29',
    800: '#A21622',
    900: '#81000B'
};

export const warning = {
    50: '#FFF5F0',
    100: '#FEF0E9',
    200: '#FDCBB5',
    300: '#FDCBB5',
    400: '#ED8936',
    500: '#FA7E46',
    600: '#E4642A',
    700: '#CC461B',
    800: '#BA3409',
    900: '#972500'
};

export const teal = {
    100: '#E7FDF8',
    200: '#CFFBF4',
    300: '#B3F3EE',
    400: '#9CE5E7',
    500: '#7ACED7',
    600: '#59A7B8',
    700: '#3D829A',
    800: '#265F7C',
    900: '#174567'
};

export const purple = {
    100: '#FBD7F4',
    200: '#F8B0F0',
    300: '#EA84E6',
    400: '#CF61D5',
    500: '#A833B9',
    600: '#85259F',
    700: '#661985',
    800: '#4A106B',
    900: '#350958'
};

export const whiteAlpha = {
    50: '#FFFFFF0A',
    100: '#FFFFFF0F',
    200: '#FFFFFF14',
    300: '#FFFFFF29',
    400: '#FFFFFF3D',
    500: '#FFFFFF5C',
    600: '#FFFFFF7A',
    700: '#FFFFFFA3',
    800: '#FFFFFFCC',
    900: '#FFFFFFEB'
};

export const baseColors = {
    black,
    white,
    primary,
    secondary,
    neutral,
    success,
    error,
    warning,
    teal,
    purple,
    whiteAlpha
};
