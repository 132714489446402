import { Box,
        Text,
        Divider,
        Flex,
        Modal,
        ModalBody,
        ModalCloseButton,
        ModalContent,
        ModalFooter,
        ModalHeader,
        ModalOverlay,
        PopoverArrow,
        PopoverBody,
        PopoverCloseButton,
        PopoverContent,
        createStandaloneToast,
        useBoolean,
        useDisclosure
    } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useGetDownloadReport } from "../../api/queries/certificate-reporting/new-certificates/use-get-download";
import { useDownload } from "../../hooks/use-download";
import { DownloadModalProps } from "./download-types";
import { ModalPrintButton, PopoverPrintButton } from "./print-buttons";
import { t } from "@lingui/macro";
import { displayDownloadErrorToast } from "../toasts/toasts";
import { useAppDispatch } from "../../store/store";
import { clearCheckedItems, clearIdentifiersToDownload } from "../../containers/download/download-container.slice";
import { BatchModal } from "./batch-modal";
import { useEnvConfig } from "../../env-config";
import { usePdfName } from "./use-pdf-name";
import { useClassGroupYearName } from "./use-class-group-year-name";
import { RankDropdown } from "../rank-tiles/rank-dropdown";
import { Identifier } from "../../api/queries/certificate-reporting/api-types";

export function DownloadModal({variant, name='', count, isOpen, identifiers, identifierStrings, onClose, setIsDownloading}: DownloadModalProps) {
    const [fullColor, setFullColor] = useBoolean(false)
    const [requestIdentifier, setRequestIdentifier] = useState('')
    const [identifiersToDownload, setIdentifiersToDownload] = useState<string[]>(
        identifierStrings ? identifierStrings : identifiers?.flatMap((item : Identifier) => item.identifiers))
    const { mutation, mutate } = useDownload(identifiersToDownload, fullColor);
    const { toast } = createStandaloneToast();
    const pdfName = usePdfName(name, identifiersToDownload.length, false)
    const downLoadQuery = useGetDownloadReport(requestIdentifier !== '' && requestIdentifier !== 'done', requestIdentifier, pdfName)
    const dispatch = useAppDispatch()
    const { DOWNLOAD_LIMIT } = useEnvConfig()
    const { isOpen: isBatchOpen, onOpen: onBatchOpen, onClose: onBatchClose } = useDisclosure()
    const classGroupHeading = useClassGroupYearName()
    const fullColorName = t`download.fullColour`
    const printFriendlyName = t`download.printFriendly`

    if (downLoadQuery.isSuccess) {
        setRequestIdentifier('done')
    }
    if (downLoadQuery.isError) {
        toast.close('genericErrorToast')
        displayDownloadErrorToast()
    }
    useEffect(() => {
        if ( requestIdentifier === 'done' && variant === 'popover') {
            dispatch(clearCheckedItems())
            dispatch(clearIdentifiersToDownload())
        }
    },[dispatch, requestIdentifier, variant])
    
    useEffect( () => {
        if (setIsDownloading) {
            if (downLoadQuery.isFetching || mutation.status === 'loading') {
                setIsDownloading(true)
            } else {
                setIsDownloading(false)
            }
        }
    }, [downLoadQuery, setIsDownloading, mutation]); 
    
    useEffect( () => {
        function handleMutation() {
            if (mutation.status === 'success' && mutation.data) {
                const requestData = mutation.data as { data: string }
                if (requestData) {
                    setRequestIdentifier(requestData?.data)
                }
                mutation.reset();
            } else if (mutation.status === 'error') {
                mutation.reset();
                toast.close('genericErrorToast')
                displayDownloadErrorToast()
                setRequestIdentifier('')
            }
        }
      handleMutation();
      // eslint-disable-next-line
    }, [mutation]);

    function onFullColorDownload() {
        setFullColor.on()
        if (identifiersToDownload.length > DOWNLOAD_LIMIT) {
            onBatchOpen()
        } else {
            mutate()
        }
        onClose()
    }

    function onPrintFriendlyDownload() {
        setFullColor.off()
        if (identifiersToDownload.length > DOWNLOAD_LIMIT) {
            onBatchOpen()
        } else {
            mutate()
        }
        onClose()
    }

    return (
        <>
            {variant === 'modal' ? 
            <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
                <ModalOverlay />
                <ModalContent borderRadius={'20px'}>
                { name && 
                    <ModalHeader 
                        fontSize={'32px'}
                        fontWeight={'400'}
                        pl={'40px !important'}
                        bg={'linear-gradient(0deg, #62D6F8 0%, #62D6F8 100%)'}
                        borderRadius={'16px 16px 0px 0px'}
                    >
                        {`${t`download`} ${name} ${count > 1 ? t`certificates` : t`certificate`}`}
                    </ModalHeader> }
                <ModalCloseButton m={'15px'} borderRadius={'full'} bg={'rgba(255, 255, 255, 0.48)'}/>
                <ModalBody fontSize={'18px'} fontWeight={'400'} padding={'30px !important'} >
                    <Text>{t`download.message`}</Text>
                    <Flex
                        mt={'20px'}
                        p={'20px 40px'}
                        align={'center'}
                        boxShadow={'0px 1px 8px 0px rgba(0, 25, 55, 0.05)'}
                        borderRadius={'15px'}
                        direction={'column'}
                    >
                        <Box>{classGroupHeading}</Box>
                        <Box h={'50px'} m={'10px'}>
                            <RankDropdown
                                totalCount={count}
                                name={name}
                                identifiers={identifiers}
                                identifiersToDownload={identifiersToDownload}
                                setIdentifiersToDownload={setIdentifiersToDownload}
                            />
                        </Box>
                    </Flex>
                </ModalBody>
                <ModalFooter justifyContent={'center'}>
                    <ModalPrintButton onClick={onFullColorDownload} name={`${fullColorName} (${identifiersToDownload.length})`} />
                    <ModalPrintButton onClick={onPrintFriendlyDownload} name={`${printFriendlyName} (${identifiersToDownload.length})`} />
                </ModalFooter>
                </ModalContent>
            </Modal>
        :
        <PopoverContent backgroundColor={'white'} borderRadius={'20px'} >
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody pl={0} pr={0}>
                <Flex flexDirection={'column'}>
                    <PopoverPrintButton onClick={onFullColorDownload} name={t`download.fullColour`} />
                    <Divider />
                    <PopoverPrintButton onClick={onPrintFriendlyDownload} name={t`download.printFriendly`} />
                </Flex>
            </PopoverBody>
        </PopoverContent>}
        <BatchModal
            count={identifiersToDownload.length}
            name={name}
            isOpen={isBatchOpen}
            identifiers={identifiersToDownload}
            onClose={onBatchClose}
            fullColor={fullColor}
        />
    </>
    )
}