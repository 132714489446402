import { NetworkErrorPage } from "@3plearning/chakra-student-components";
import { Box } from "@chakra-ui/react";
// import { useTranslation } from "react-i18next";
import DotFlashing from './../dot-flashing/dot-flashing';
import { ReactComponent as NoInternet } from "../../assets/no-internet.svg";
import { renderToStaticMarkup } from 'react-dom/server';
import { t } from "@lingui/macro";

interface OfflineModalProps {
    isOpen: boolean 
}

const OfflineErrorModal = (props: OfflineModalProps) => {
    // const { t } = useTranslation();
    const messageText:string = t`modal.lostInternet.message`;
    const svgImage = encodeURIComponent(renderToStaticMarkup(<NoInternet />));
    const noInternetImage = `data:image/svg+xml,${svgImage}`;
    return (
        <NetworkErrorPage
            size={'2xl'}
            headingText=''
            messageText={messageText}
            isOpen={props.isOpen}
            buttons={[]}
            imageSrc={noInternetImage}
            modalContentStyles={{
                sx:
                {
                    'boxShadow' : '0px 0px 0px 8px rgba(255, 255, 255, 0.3)',
                    'borderRadius': '20px' 
                }
            }}
            contentStyles={{
                sx: {
                    'img': {
                        'margin': '0px',
                        'borderRadius': '20px'
                    }
                }
            }}
            modalBodyStyles={{
                padding: '0px !important'
            }}
        >
            <Box w='100%' textAlign='center'>
                <DotFlashing />
            </Box>
        </NetworkErrorPage>
    )
}

export default OfflineErrorModal;
