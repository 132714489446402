import { Tr, Td, Skeleton } from "@chakra-ui/react";

export function GridRowSkeleton() {
    return (
        <Tr>
            <Td width={'340px'}
                height={'70px'}
                p={'12px 20px'}
                borderBottom={'1px solid'}
                borderColor={'#DBE0E6'}
                bgColor={'#F8FBFC'}>
                <Skeleton width={'340px'} height={'40px'} borderRadius={'12px'} />
            </Td>
            <Td
                borderRight= '1px solid #DBE0E6'
                bgColor={'#F8FBFC'}
                boxShadow='0px -1px 0px 0px #E2E8F0 inset'
            >
                <Skeleton width={'20px'} height={'20px'} />
            </Td>
            { [...Array(9)].map( ( el, i) => ( 
                <Td
                key={i} 
                borderBottom={'1px solid #DBE0E6'}
                borderRight={'1px solid #DBE0E6'}
            >
                <Skeleton width={'50px'} height={'50px'} borderRadius={'12px'} />
            </Td>
            ) ) }
            
        </Tr>
    )
    
}