import { useBoolean } from "@chakra-ui/react"

import { GridRow } from "./grid-row"
import { CheckBoxProps, GroupRowProps, RankDetailProps } from "./grid-types"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/store"
import { addIdentifiersToDownload, removeIdentifiersToDownload } from "../../containers/download/download-container.slice"
import { StudentRow } from "./student-row"
import { getCertificatesCountForGroup } from "./__helpers__/result-grid-helper"
import { CERTIFICATE_ROW_VARIANT } from "../../utils/enums"
import { Identifier } from "../../api/queries/certificate-reporting/api-types"

export function GroupRow({ group, groupIndex, classId, classIndex, checkedItems, setCheckedItems }: GroupRowProps) {
    const [isGroupExpanded, setIsGroupExpanded] = useBoolean(false)
    const [groupId, setGroupId] = useState<number | string>(0)
    const dispatch = useAppDispatch()
    const [checkedStudents, setCheckedStudents] = useState(group.students.map(() => checkedItems[groupIndex]))
    const [allStudentsChecked, setAllStudentsChecked] = useState(checkedItems[groupIndex])
    const [isIndeterminate, setIsIndeterminate] = useState(false)

    useEffect(() => {
        setAllStudentsChecked(checkedStudents.every(Boolean));
        setIsIndeterminate(checkedStudents.some(Boolean) && !allStudentsChecked);
    }, [allStudentsChecked, checkedStudents])

    const {
        studentCertificates
    } = useAppSelector(
        rootState => rootState.certificateResultsSlice
    )

    useEffect(() => {
        setCheckedStudents(group.students.map(() => checkedItems[groupIndex])) 
    }, [checkedItems, groupIndex, group.students])

    function onRowExpand(id: number | string) {
        setIsGroupExpanded.toggle()
        setGroupId(id)
    }

    function onCheckBoxChange(e: any) {
        setCheckedItems([
            ...checkedItems.slice(0, groupIndex),
            e.target.checked,
            ...checkedItems.slice(groupIndex + 1)
        ])
        setCheckedStudents(group.students.map(() => e.target.checked))
        const identifiers  = rankDetailProps().total.identifiers as Identifier[]
        const totalIdentifiers = identifiers.flatMap(item => item.identifiers)
        if (e?.target?.checked) {
            dispatch(addIdentifiersToDownload({ totalIdentifiers }))
        } else {
            dispatch(removeIdentifiersToDownload({ totalIdentifiers }))
        }
    }
    const checkBoxProps = () => ({
        checkBoxSize: 'md',
        checkBoxValue: `${classId}_${group.id}`,
        isChecked: checkedItems[groupIndex],
        isIndeterminate,
        onCheckBoxChange
    } as CheckBoxProps)

    const rankDetailProps = () => ({
        ...getCertificatesCountForGroup(group, studentCertificates) as RankDetailProps
    })

    const gridRowProps = () => ({
        name: group.name,
        id: group.id,
        rowType: CERTIFICATE_ROW_VARIANT.group,
        isRowExpanded: isGroupExpanded,
        isLastRecord: checkedItems.length -1 === groupIndex,
        onRowExpand,
        checkBoxProps: checkBoxProps(),
        rankDetailProps: rankDetailProps()
    })

    return (
        <>
            <GridRow {...gridRowProps()}/>
            { isGroupExpanded && group.id === groupId && group.students.map(
                (student, index) => {
                    return <StudentRow
                                key={student.userId}
                                classId={classId}
                                groupId={groupId}
                                student={student}
                                index={index}
                                groupIndex={groupIndex}
                                classIndex={classIndex}
                                checkedItems={checkedStudents}
                                setCheckedItems={setCheckedStudents}
                            />
                })
            }
        </>
    )
}