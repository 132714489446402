import { mergeThemeOverride, ThemeConfig } from "@chakra-ui/react"
import { studentComponentsTheme } from '@3plearning/chakra-student-components'
import { fonts } from "./fonts";
import { checkboxTheme } from "./components/check-box";
import { alertTheme } from "./components/alert";
import { baseColors } from "../colors/base-colors";
import { breakpoints } from "./breakpoints";

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  cssVarPrefix: '3p'
}

const theme = mergeThemeOverride(studentComponentsTheme, {
    config,
    fonts,
    colors: baseColors,
    breakpoints,
    components: {
        Alert: alertTheme,
        CheckBox: checkboxTheme
    }
});

export default theme
