import { Button, Divider, Flex } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { useState } from "react";

interface GridNavigatorProps {
    scrollRight: () => void
    scrollLeft: () => void
}

export function GridNavigator({scrollRight, scrollLeft} : GridNavigatorProps) {
    const [disableLeftButton, setDisableLeftButton] = useState(true)
    const [disableRightButton, setDisableRightButton] = useState(false)
    

    const onRightClick = () => {
        scrollRight()
        setDisableRightButton(true)
        setDisableLeftButton(false)
    }
    
    const onLeftClick = () => {
        scrollLeft()
        setDisableLeftButton(true)
        setDisableRightButton(false)
    }

    return (
        <Flex
            flexDirection={'column'}
            position={'absolute'}
            zIndex={'1000'}
            right={'160px'}
            top={'4px'}
            backgroundColor={'white'}
            boxShadow='8px 4px 12px 0px rgba(0, 0, 0, 0.20)'
            border={'1px solid'}
            borderColor={'primary.400'}
            borderRadius={'10px'}
            justifyContent={'center'}
            gap={'10px'}
            p={'5px 2px'}
            >
            <Button variant={'unstyled'} width={'40px'} height={'40px'} onClick={onRightClick} isDisabled={disableRightButton}>
                <ChevronRightIcon width={'40px'} height={'40px'} color={'primary.400'} />
            </Button>
            
            <Divider alignSelf={'center'} width={'40px'}/>
            <Button variant={'unstyled'} width={'40px'} height={'40px'} onClick={onLeftClick} isDisabled={disableLeftButton}>
                <ChevronLeftIcon width={'40px'} height={'40px'} color={'primary.400'}/>
            </Button>
        </Flex>
    )
}