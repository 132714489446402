import { Flex, Box, Circle, Image } from "@chakra-ui/react";
import { Dropdown } from "../dropdown/dropdown";
import { useAppSelector } from "../../store/store";
import { Group } from "../../api/queries/certificate-reporting/api-types";
import { DropdownValue } from "../dropdown/dropdown.types";
import GroupImage  from "../../assets/group.svg";
import { dropdownContainerWidth } from "../dropdown/dropdown.styled";


interface GroupFilterProps {
    onGroupChange : (value: DropdownValue) => void
}

export function GroupFilter({ onGroupChange } : GroupFilterProps) {

    const {
        group
    } = useAppSelector(
        rootState => rootState.filtersContainerSlice
    )
    const groupData  = group.data as Group[]

    function getGroupOptions() {
        return groupData?.map(({
            id,
            name
        }) => ({
            value: id,
            label: name,
        })) || [];
    }

    return (
        <Flex
            h={'auto'}
            w={'auto'}
        >
            <Flex w={'100%'} alignItems={'center'} position={'relative'}>
                <Circle size={8} backgroundColor={'secondary.300'}>
                    <Image width={'25px'} height={'25px'} src={GroupImage} />
                </Circle>
                <Box w={'100%'} maxW={dropdownContainerWidth}>
                    <Dropdown
                        options={getGroupOptions()}
                        value={group.selected?.id || 0}
                        onOptionClick={onGroupChange}
                        label={group.selected?.name || ''}
                    />
                </Box>
                
            </Flex>
        </Flex>
    )
}