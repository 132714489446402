import {
    Button,
    Flex,
    PopoverTrigger,
    Text
} from '@chakra-ui/react';
import React from 'react';

import { DropdownTestIds } from '../dropdown-constants';
import {
    DropdownDisplay,
    DropdownOption,
    DropdownVariant
} from '../dropdown.types';
import * as styled from './dropdown-select.styled';
import { DropdownChevronDown } from '../dropdown-icons/dropdown-chevron-down';
import { DropdownChevronUp } from '../dropdown-icons/dropdown-chevron-up';
import { dropdownButtonBorderRadius } from '../dropdown.styled';

type DropdownSelectProps = {
    isOpen: boolean
    variant: DropdownVariant
    options: Array<DropdownOption>
    labelConstant: DropdownDisplay
    label: string
};

export const DropdownSelect = ({
    isOpen,
    variant,
    label,
    options,
    labelConstant
}: DropdownSelectProps) => {
    const isSingleItem = options.length === 1;
    const getRightIcon = () => {
        if (isSingleItem) return {};

        const defaultProps = {
            w: '20px',
            h: '20px'
        };
        const returnedRightIcon = isOpen ? (
            <DropdownChevronUp
                {...defaultProps}
                data-testid={DropdownTestIds.chevronUp}
            />
        ) : (
            <DropdownChevronDown
                data-testid={DropdownTestIds.chevronDown}
                {...defaultProps}
            />
        );

        return { rightIcon: returnedRightIcon };
    };
    const dropdownButtonDefaultExpanded = {
        color: 'primary.400',
        borderRadius: dropdownButtonBorderRadius
    };
    return (
        <PopoverTrigger data-testid={DropdownTestIds.popoverHeader}>
            <Button
                data-testid={DropdownTestIds.selectButton}
                border={variant === DropdownVariant.fullStroke ? '1px solid' : ''}
                borderRadius={variant === DropdownVariant.fullStroke ? dropdownButtonBorderRadius : '0'}
                borderBottom= '1px solid'
                borderColor='#E2E8F0'
                fontSize={{ sm: '13px', xxl:'16px' }}
                margin={'0 10px'}
                _hover= {{
                    marginTop: '1px',
                    border: variant === DropdownVariant.fullStroke ? '2px solid' : '',
                    borderBottom: '2px solid',
                    borderColor: 'primary.400',
                    borderRadius: dropdownButtonBorderRadius,
                }}
                _expanded={{
                    marginTop: '1px',
                    borderBottom: '2px solid',
                    borderColor: 'primary.400',
                    ...dropdownButtonDefaultExpanded
                }}
                pointerEvents={isSingleItem ? 'none' : 'all'}
                width={'100%'}
                p={'0 12px'}
                variant={variant}
                {...getRightIcon()}
            >
                <Flex w={'calc(100% - 24px)'}>
                    <styled.text
                        align={'left'}
                        color={'#001937'}
                        data-testid={DropdownTestIds.selectLabelText}
                        isTruncated
                    >
                        {label}
                    </styled.text>
                    {labelConstant && (
                        <Text
                            color={'#001937'}
                            data-testid={DropdownTestIds.selectLabelConstantText}
                            marginLeft={'5px'}
                        >
                            {labelConstant}
                        </Text>
                    )}
                </Flex>
            </Button>
        </PopoverTrigger>
    );
};
