import { useMutation } from '@tanstack/react-query';
import { useAuthToken, useFetcher } from "../use-fetcher";
import { useEnvConfig } from '../../../../env-config';
import { PRODUCT_ID } from '../../../../utils/consts';

export function usePastSubmitDownloadRequest (
    startDate:  string,
    endDate: string,
    classroomId: number,
    groupId: number,
    type: number,
    batchSize: number,
    batchNumber: number
    ) {

    const { CERTIFICATE_GATEWAY } = useEnvConfig();

    const payload = {
        productId: PRODUCT_ID,
        startDate,
        endDate,
        classroomId,
        groupId,
        type
    }
    const batchPayload = (batchSize && batchNumber) ? {...payload, batchSize, batchNumber} : payload

    const postSubmitDownloadRequest = useFetcher(
        CERTIFICATE_GATEWAY,
        "certificates/download",
        "POST",
        {headers: {AuthToken : useAuthToken()}},
        {
            ...batchPayload
        });

    return useMutation({
        mutationFn: postSubmitDownloadRequest,
        mutationKey: ['SubmitPastRequest']
    })
}
