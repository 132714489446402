import { Component, type ErrorInfo, PropsWithChildren } from 'react'

import { GeneralErrorPage } from '@3plearning/chakra-student-components'
import { RippleButton } from '@3plearning/chakra-mathletics-theme'

type Props = PropsWithChildren
type State = {
  hasError: boolean
}

/**
 * Basic ErrorBoundary component hooks into the componentDidCatch
 *
 * @see https://reactjs.org/docs/react-component.html#error-boundaries
 */
export class AppErrorBoundary extends Component<Props, State> {
  constructor(props: PropsWithChildren) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO Sentry logging in FE
    console.error('AppErrorBoundary', { error, errorInfo })
  }

  render() {
    // const headingText = t('modal.error.header')
    const messageText = 'Uh-oh! Something doesn’t add up.'
    const retryText = 'Home'
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <GeneralErrorPage
          // headingText={headingText}
          messageText={messageText}
          buttons={[() => (
            <RippleButton
              onClick={() => this.setState({ hasError: false })}
            >
              {retryText}
            </RippleButton>
          )]}
        />
      )
    }

    // Return children components in case of no error

    return this.props.children
  }
}

export default AppErrorBoundary
